import Box from "@mui/material/Box";
import Progress from "./circleLoading";

export default function Loading() {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "85vh",
      }}
    >
      <Progress color="primary" size={100} />
    </Box>
  );
}

import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import ErrorPage from "Components/Shared/error";
import Loading from "Components/Shared/loading";
import StickyHeadTable from "Components/Shared/table";
import Wrapper from "Components/Wrapper";
import { useState } from "react";
import { useQuery } from "react-query";
import { request, toLocaleDate } from "Utils";
import NoContentFound from "Components/Shared/noContentFound";
import { GetBdcFunc, ListInt } from "./type";
import Menu from "./menu";

const getBdcList = async () => {
  const { data } = await request.get({
    url: `/bdc/exchange-fee-history`
  });
  return data;
};

const GetBdcFee: GetBdcFunc = () => {
  const [pageId, setPageId] = useState(1);
  const [limit, setLimit] = useState(10);

  const { data, error, isLoading, refetch } = useQuery({
    queryKey: ["Users", pageId, limit],
    queryFn: getBdcList,
    retry: 2
  });

  const columns = [
    { minWidth: 20, name: "No", key: "index" },
    { minWidth: 20, name: "Bdc Id", key: "bdcId" },
    { minWidth: 50, name: "Currency", key: "currency" },
    { minWidth: 50, name: "Amount", key: "amount" },
    { minWidth: 30, name: "Order Id", key: "orderId" },
    { minWidth: 30, name: "Type", key: "type" },
    { minWidth: 50, name: "Created At", key: "createdAt" }
  ];

  const bdcLists = data?.map((list: ListInt, index: number) => ({
    index: ++index + limit * (pageId - 1),
    ...list,
    isOnline: list.isOnline ? "YES" : "NO",
    createdAt: toLocaleDate(list?.createdAt)
  }));

  if (isLoading) return <Loading />;
  if (error) return <ErrorPage error={error} />;

  return (
    <Wrapper>
      <Container maxWidth="lg" sx={{ height: "85.5vh" }}>
        <Box display="flex" justifyContent="flex-end">
          <Menu apiEndpoint="/bdc/paid-fee" refetch={refetch} />
        </Box>
        {bdcLists?.length ? (
          <StickyHeadTable
            refetch={refetch}
            data={bdcLists}
            dataLength={data?.length}
            columns={columns}
            setLimit={setLimit}
            limit={limit}
            setPageId={setPageId}
            pageId={pageId}
          />
        ) : (
          <NoContentFound />
        )}
      </Container>
    </Wrapper>
  );
};

export default GetBdcFee;

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Chart } from "react-google-charts";

interface OfferStatsInt {
  currency: string;
  totalAmountRequested: number;
  totalOffers: number;
  highestRate: number;
}

export default function PieChart({
  offerStats,
}: {
  offerStats: Array<OfferStatsInt> | [];
}) {
  const labels = offerStats?.map(({ currency, totalOffers }) => [
    currency,
    totalOffers,
  ]);
  const data = [["Country", "request"], ...labels];
  const options = {
    title: "Total amount requested",
  };

  return (
    <>
      {labels !== undefined && (
        <Card sx={{ boxShadow: "0px 1px 70px 1px #f1f1f1", mt: 5 }}>
          <CardContent sx={{ textAlign: "center" }}>
            <Chart
              chartType="PieChart"
              data={data}
              options={options}
              width={"100%"}
              height={"400px"}
            />
          </CardContent>
        </Card>
      )}
    </>
  );
}

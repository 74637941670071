import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { KycCardFunc } from "./type";
import { Link } from "react-router-dom";

const KycCard: KycCardFunc = ({ status }) => {
  return (
    <Link to={`/kyc-requests/${status.status}`}>
      <Card>
        <CardContent
          sx={{
            textAlign: "center",
            height: 200,
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <Typography
            variant="body1"
            sx={{ fontWeight: 700, fontSize: 30, color: "#333333" }}
            color="text.secondary"
            gutterBottom
          >
            {status.title}
          </Typography>
        </CardContent>
      </Card>
    </Link>
  );
};
export default KycCard;

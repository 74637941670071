import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import InputField from "Components/Shared/textField";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";

import { useForm } from "Utils/hooks";
import ActionButton from "Components/Shared/button";
import { request } from "Utils";
import { useState } from "react";
import Progress from "Components/Shared/circleLoading";
import DialogComponent from "Components/Shared/dailog";
import { useMutation } from "react-query";
import { AddVAsFunc } from "./type";
import { Typography } from "@mui/material";

const AddVAs: AddVAsFunc = ({
  toggleToast,
  isOpen,
  closeDialog,
  refetch,
  services,
  iso2,
  loading,
}) => {
  const { getData, values, submit, reset } = useForm(submitForm);
  const [isLoading, setIsloading] = useState(false);
  const [service, setService] = useState("");
  const mutation = useMutation(
    async () => {
      setIsloading(true);
      if (values.providerDiscount < 0) {
        setIsloading(false);
        throw toggleToast("Providers Discount can not be less than 0");
      }
      if (values.discount < 0) {
        setIsloading(false);
        throw toggleToast("Discount can not be less than 0");
      }
      if (values.discount > values.providerDiscount) {
        setIsloading(false);
        throw toggleToast(
          "Discount can not be greater than the providers discount",
        );
      }
      values.discount = Number(values.discount);
      values.providerDiscount = Number(values.providerDiscount);
      return await request.post({
        url: `/vas/${iso2}`,
        data: { ...values, service },
      });
    },
    {
      onSuccess: ({ message }) => {
        reset();
        toggleToast(message);
        refetch();
        setIsloading(false);
        closeDialog();
      },
      onError: ({ message }) => {
        toggleToast(message);
        setIsloading(false);
      },
    },
  );
  function submitForm() {
    mutation.mutate();
  }

  return (
    <div>
      <DialogComponent
        open={isOpen}
        onClose={closeDialog}
        title="Add New VAS"
        maxWidth="xs"
      >
        <Box py={4}>
          {loading ? (
            <Typography variant="body2">
              Getting VAS....
              <Progress color="primary" sx={{ ml: 0.8 }} size={18} />
            </Typography>
          ) : (
            <form onSubmit={submit}>
              <Stack spacing={4}>
                <InputField
                  type="number"
                  label="In-house Discount"
                  name="discount"
                  onChange={getData}
                  required
                />
                <InputField
                  type="number"
                  label="Provider Discount"
                  name="providerDiscount"
                  onChange={getData}
                  required
                />

                <FormControl fullWidth>
                  <InputLabel>Service*</InputLabel>
                  <Select
                    name="serviceId"
                    labelId="service"
                    label="Service"
                    value={values.name}
                    onChange={(e: any) => getData(e)}
                    required
                  >
                    {services?.map(({ name, _id }, index: number) => (
                      <MenuItem
                        key={`${index}-service`}
                        value={_id}
                        id={name}
                        onClick={() => {
                          setService(name);
                        }}
                      >
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <ActionButton type="submit" variant="contained">
                  Add VAS
                  {isLoading && (
                    <Progress sx={{ color: "#fff", ml: 0.8 }} size={18} />
                  )}
                </ActionButton>
              </Stack>
            </form>
          )}
        </Box>
      </DialogComponent>
    </div>
  );
};
export default AddVAs;

import Stack from "@mui/material/Stack";
import MenuItem from "@mui/material/MenuItem";

import { useDailog, useForm } from "Utils/hooks";
import ActionButton from "Components/Shared/button";
import InputField from "Components/Shared/textField";
import DialogComponent from "Components/Shared/dailog";
import { useState } from "react";
import Progress from "Components/Shared/circleLoading";
import Box from "@mui/material/Box";
import { request } from "Utils";
import { useMutation } from "react-query";
import Typography from "@mui/material/Typography";

const BankTransfer = ({ toggleToast, refetch }: any) => {
  const { isOpen, closeDialog, openDialog } = useDailog();
  const { values, getData, submit } = useForm(submitForm);
  const [isLoading, setIsloading] = useState(false);
  const [getAccountNameIsLoading, setGetAccountNameIsloading] = useState(false);
  const [recipientName, setRecipientName] = useState("");
  const sx = {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    alignItems: "center",
    width: "100%"
  };
  const mutation = useMutation(
    async () => {
      return await request.post({
        url: "/nsano/bank-transfer",
        data: values
      });
    },
    {
      onSuccess: ({ message }) => {
        toggleToast(message);
        refetch();
        setIsloading(false);
        closeDialog();
      },
      onError: ({ message }) => {
        toggleToast(message);
        setIsloading(false);
        closeDialog();
      }
    }
  );

  function submitForm(event: React.FormEvent) {
    event.preventDefault();
    setIsloading(true);
    
    mutation.mutateAsync().then(() => {
        closeDialog(); // Close the dialog after successful submission
    }).catch(() => {
        // Handle error, dialog will still close
        closeDialog();
    }).finally(() => {
        setIsloading(false); // Reset loading state
    });
}
  // function submitForm() {
  //   mutation.mutate();
  // }

  async function getRecipientName() {
    try {
      setGetAccountNameIsloading(true);
      const { data } = await request.post({
        url: "/nsano/verify-bank-details",
        data: {
          bankCode: values.provider,
          accountNo: values.recipientAccountNo
        }
      });
      values.recipientName = data.accountName;
      setRecipientName(data.accountName);
      setGetAccountNameIsloading(false);
    } catch (error) {
      setGetAccountNameIsloading(false);
    }
  }

  return (
    <div>
      <MenuItem onClick={openDialog}>Bank transfer</MenuItem>
      <DialogComponent
        open={isOpen}
        onClose={closeDialog}
        title="Nsano bank transfer"
        maxWidth="sm"
      >
        <Stack spacing={3} py={2}>
          <form onSubmit={submitForm}>
            <Stack spacing={3} py={3}>
              <Box sx={sx}>
                <InputField
                  type="text"
                  label="Provider"
                  name="provider"
                  onChange={getData}
                  
                  sx={{ width: { xs: "100%", md: "48%" } }}
                 
                  required
                />
                <InputField
                  type="number"
                  label="Recipient account number"
                  name="recipientAccountNo"
                  onChange={getData}
                  onBlur={getRecipientName}
                  sx={{ width: { xs: "100%", md: "48%" } }}
                  required
                />
              </Box>
              <Box sx={sx}>
                <InputField
                  type="text"
                  label="Recipient Name"
                  name="recipientName"
                  onChange={getData}
                  
                  sx={{ width: { xs: "100%"} }}
                 
                  required
                />
                </Box>
              <Box>
                {getAccountNameIsLoading ? (
                  <Progress sx={{ color: "green", ml: 0.8 }} size={18} />
                ) : (
                  <Typography sx={{ color: "green" }}>
                    {recipientName || values.recipientName}
                  </Typography>
                )}
              </Box>
              <Box sx={sx}>
                <InputField
                  type="text"
                  label="Sender name"
                  name="senderName"
                  onChange={getData}
                  sx={{ width: { xs: "100%", md: "48%" } }}
                  onKeyPress={(event) => {
                    if (!/^[^\d]*$/.test(event.key)) {
                      event.preventDefault(); // Blocks numbers but allows special characters
                    }
                  }}
                  required
                />
                <InputField
                  type="number"
                  label="Sender account number"
                  name="senderAccountNo"
                  onChange={getData}
                  sx={{ width: { xs: "100%", md: "48%" } }}
                />
              </Box>
              <Box sx={sx}>
                <InputField
                  type="text"
                  label="Recipient country"
                  name="recipientCountry"
                  onChange={getData}
                  sx={{ width: { xs: "100%", md: "48%" } }}
                  onKeyPress={(event) => {
                    if (!/^[^\d]*$/.test(event.key)) {
                      event.preventDefault(); // Blocks numbers but allows special characters
                    }
                  }}
                  required
                />
                <InputField
                  type="text"
                  label="Source currency"
                  name="sourceCurrency"
                  onChange={getData}
                  sx={{ width: { xs: "100%", md: "48%" } }}
                  onKeyPress={(event) => {
                    if (!/^[^\d]*$/.test(event.key)) {
                      event.preventDefault(); // Blocks numbers but allows special characters
                    }
                  }}
                  required
                />
              </Box>
              <Box sx={sx}>
                <InputField
                  type="number"
                  label="Amount"
                  name="amount"
                  onChange={getData}
                  sx={{ width: { xs: "100%", md: "48%" } }}
                  required
                />

                <InputField
                  type="number"
                  label="Exchange rate"
                  name="exchangeRate"
                  onChange={getData}
                  sx={{ width: { xs: "100%", md: "48%" } }}
                  required
                />
              </Box>
              <Box sx={sx}>
                <InputField
                  type="text"
                  label="Reference"
                  name="reference"
                  onChange={getData}
                  sx={{ width: { xs: "100%", md: "48%" } }}
                  required
                />
                <InputField
                  type="text"
                  label="Narration"
                  name="narration"
                  onChange={getData}
                  sx={{ width: { xs: "100%", md: "48%" } }}
                />
              </Box>

              <ActionButton type="submit" variant="contained">
               Bank transfer
                {isLoading && (
                  <Progress sx={{ color: "#fff", ml: 0.8 }} size={18} />
                )}
              </ActionButton>
            </Stack>
          </form>
        </Stack>
      </DialogComponent>
    </div>
  );
};

export default BankTransfer;

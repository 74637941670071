import Stack from "@mui/material/Stack";
import InputField from "Components/Shared/textField";
import { useForm } from "Utils/hooks";
import ActionButton from "Components/Shared/button";
import { handleError, request } from "Utils";
import { useState } from "react";
import Progress from "Components/Shared/circleLoading";
import DialogComponent from "Components/Shared/dailog";
import { useMutation } from "react-query";
import { AddSchoolTypeFunc } from "./type";
import { Box } from "@mui/material";


const AddSchools: AddSchoolTypeFunc = ({
  toggleToast,
  isOpen,
  closeDialog,
  refetch,
  countryId
}) => {
  const { getData, values, submit, reset } = useForm(submitForm);
  const [isLoading, setIsloading] = useState(false)


  const mutation = useMutation(
    async () => {
      setIsloading(true);
      return await request.patch({
        url: `/school-config/${countryId}/add-school`,
        data: values,
      });
    },
    {
      onSuccess: ({ message }) => {
        reset();
        toggleToast(message);
        refetch();
        setIsloading(false);
        closeDialog();
      },
      onError: (err) => {
        toggleToast(handleError(err).message);
        setIsloading(false);
      },
    },
  );
  function submitForm() {
    mutation.mutate();
  }

  return (
    <div>
      <DialogComponent
        open={isOpen}
        onClose={closeDialog}
        title="Add School"
        maxWidth="xs"
      >
        <form onSubmit={submit}>
          <Stack spacing={4} py={4}>
            <InputField
              type="text"
              label="School Name"
              name="name"
              onChange={getData}
              required
            />
            <InputField
              type="text"
              label="Bank Name"
              name="bankName"
              onChange={getData}
              required
            />
            <InputField
              type="text"
              label="Bank Address"
              name="bankAddress"
              onChange={getData}
              required
            />
             <Box sx={{ display : 'flex', gap : '10px'}}>
             <InputField
              type="text"
              label="Account Name"
              name="accountName"
              onChange={getData}
              required
            />
            <InputField
              type="text"
              label="Account Number"
              name="accountNo"
              onChange={getData}
              required
            />
             </Box>
            <Box sx={{ display : 'flex', gap : '10px'}}>
            <InputField
              type="text"
              label="IBAN"
              name="iban"
              onChange={getData}
              required
            />
            <InputField
              type="text"
              label="Swift Code"
              name="swiftCode"
              onChange={getData}
              required
            />
            </Box>
            <ActionButton type="submit" variant="contained">
              Add School{" "}
              {isLoading && (
                <Progress sx={{ color: "#fff", ml: 0.8 }} size={18} />
              )}
            </ActionButton>
          </Stack>
        </form>
      </DialogComponent>
    </div>
  );
};
export default AddSchools;

import { Avatar } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

import { BalanceCardFunct } from "./types";

const BalanceCard: BalanceCardFunct = ({ currency, flag, balance }) => {
  return (
    <Card sx={{ boxShadow: "0px 1px 70px 1px #f1f1f1" }}>
      <CardContent sx={{ textAlign: "center" }}>
        <Avatar
          sx={{
            marginLeft: "auto",
            marginRight: "auto",
            marginBottom: "20px",
            display: "block",
          }}
          src={flag}
          alt={currency}
        />
        <Typography
          variant="body1"
          sx={{ fontWeight: 700, fontSize: 30, color: "#333333" }}
          color="text.secondary"
          gutterBottom
        >
          {balance?.toLocaleString()}
        </Typography>

        <Typography
          variant="body2"
          sx={{
            fontWeight: 700,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textTransform: "uppercase",
          }}
          color="text.secondary"
        >
          {" "}
          {currency}
        </Typography>
      </CardContent>
    </Card>
  );
};
export default BalanceCard;

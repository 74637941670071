import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Chart, ReactGoogleChartEvent } from "react-google-charts";
import { RatingStatsFunc } from "./types";
import { useNavigate } from "react-router-dom";

export const options = {
  title: "Total Orders Statistic",
  subtitle: "total amount requested in various currency",
};

const RatigBarChart: RatingStatsFunc = ({ offerStats }) => {
  const navigate = useNavigate();
  const labels = offerStats?.map(({ currency, totalOrders }) => [
    currency,
    totalOrders,
  ]);

  const data = [["Currency", "Orders"], ...labels];
  const readyEvent: ReactGoogleChartEvent[] = [
    {
      eventName: "select",
      callback: async ({ chartWrapper }: any) => {
        const currency = chartWrapper.getChart().getSelection();
        navigate(
          `/orders?status=SUCCESSFUL&currency=${
            offerStats[currency[0].row].currency
          }`,
        );
      },
    },
  ];
  return (
    <Card sx={{ mt: 5.5, boxShadow: "0px 1px 70px 1px #f1f1f1" }}>
      <CardContent sx={{ textAlign: "center" }}>
        <Chart
          chartType="BarChart"
          width="100%"
          height="400px"
          data={data}
          options={options}
          chartEvents={readyEvent}
        />
      </CardContent>
    </Card>
  );
};
export default RatigBarChart;

import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";

import InputField from "Components/Shared/textField";
import { useDailog, useForm } from "Utils/hooks";
import ActionButton from "Components/Shared/button";
import { request } from "Utils";
import { useState } from "react";
import Progress from "Components/Shared/circleLoading";
import DialogComponent from "Components/Shared/dailog";
import { useMutation } from "react-query";
import FabButton from "Components/Shared/fab";
import {
  AddConfigFunc,
  ConfigConfigurationProps,
  ConfigurationInt,
} from "./type";

const AddConfig: AddConfigFunc = ({ toggleToast, refetch }) => {
  const { isOpen, closeDialog, openDialog } = useDailog();
  const { getData, values, submit, reset } = useForm(submitForm);
  const [isLoading, setIsloading] = useState(false);
  const [configurations, setConfigurations] = useState<
    Array<ConfigConfigurationProps>
  >([]);
  const [data, setData] = useState([]);
  const types = ["PERCENTAGE", "FIXED", "TEXT", "BOOLEAN"];

  async function getConfigTypes() {
    try {
      const { data } = await request.get({ url: "/config-types" });
      setData(data);
      openDialog();
    } catch (error) {
      console.log(error);
    }
  }
  const mutation = useMutation(
    async () => {
      setIsloading(true);
      return await request.post({
        url: "/configuration",
        data: { ...values, configurations },
      });
    },
    {
      onSuccess: ({ message }) => {
        reset();
        toggleToast(message);
        refetch();
        setIsloading(false);
        closeDialog();
      },
      onError: ({ message }) => {
        toggleToast(message);
        setIsloading(false);
      },
    },
  );
  function submitForm() {
    mutation.mutate();
  }
  function idIncluded(id: string) {
    const value = configurations.find((element) => element.configTypeId === id);
    if (value)
      return configurations.findIndex(
        (element) => element.configTypeId === value.configTypeId,
      );
    else return null;
  }

  function setConfigurationsFunc(id: string, value: string) {
    const index = idIncluded(id);
    if (index || index === 0) configurations[index].type = value;
    else
      configurations.push({
        configTypeId: id,
        type: value,
        value: 0,
      });

    setConfigurations([...configurations]);
  }

  function setConfigurationsValue(id: string, value: number) {
    const index = idIncluded(id);
    if (index || index === 0) configurations[index].value = value;
    else
      configurations.push({
        configTypeId: id,
        type: "",
        value,
      });
    setConfigurations([...configurations]);
  }

  return (
    <div>
      <FabButton onClick={getConfigTypes} />
      <DialogComponent
        open={isOpen}
        onClose={closeDialog}
        title="Configuration"
        maxWidth="md"
      >
        <form onSubmit={submit}>
          <Stack spacing={3} py={3}>
            <Stack spacing={2} direction="row">
              <InputField
                type="text"
                label="Country"
                name="country"
                onChange={getData}
                sx={{ width: { xs: "100%", md: "50%" } }}
                required
              />
              <InputField
                type="text"
                label="currency"
                name="currency"
                onChange={getData}
                sx={{ width: { xs: "100%", md: "50%" } }}
                required
              />
              <InputField
                type="text"
                label="iso2"
                name="iso2"
                onChange={getData}
                sx={{ width: { xs: "100%", md: "50%" } }}
                required
              />
            </Stack>
            <Stack spacing={2} direction="row">
              <InputField
                type="number"
                label="Single Transaction Limit"
                name="singleTransactionLimit"
                onChange={getData}
                sx={{ width: { xs: "100%", md: "50%" } }}
                required
              />
              <InputField
                type="number"
                label="Daily Transaction Limit"
                name="dailyTransactionLimit"
                onChange={getData}
                sx={{ width: { xs: "100%", md: "50%" } }}
                required
              />
              <InputField
                type="number"
                label="Monthly Transaction Limit"
                name="monthlyTransactionLimit"
                onChange={getData}
                sx={{ width: { xs: "100%", md: "50%" } }}
                required
              />
            </Stack>
            <Stack spacing={2} pr={2}>
              {data?.map((config: ConfigurationInt, index: number) => (
                <Grid container spacing={2} key={`cofiguration ${index}`}>
                  <Grid item md={4}>
                    <Typography>{config.name}</Typography>
                  </Grid>
                  <Grid item md={4}>
                    <FormControl fullWidth>
                      <InputLabel>Sellet Type *</InputLabel>
                      <Select
                        labelId="type"
                        label="Select Type"
                        onChange={(e: any) =>
                          setConfigurationsFunc(config.id, e.target.value)
                        }
                      >
                        {types?.map((type, index) => (
                          <MenuItem key={`${index}-type`} value={type}>
                            {type}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item md={4}>
                    <InputField
                      fullWidth
                      type="text"
                      label="Value"
                      name="value"
                      onChange={(e) =>
                        setConfigurationsValue(
                          config.id,
                          Number(e.target.value)
                        )
                      }
                    />
                  </Grid>
                </Grid>
              ))}
            </Stack>

            <ActionButton type="submit" variant="contained">
              Update Configuration
              {isLoading && (
                <Progress sx={{ color: "#fff", ml: 0.8 }} size={18} />
              )}
            </ActionButton>
          </Stack>
        </form>
      </DialogComponent>
    </div>
  );
};

export default AddConfig;

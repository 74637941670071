import { useState, useMemo } from "react";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import ErrorPage from "Components/Shared/error";
import Loading from "Components/Shared/loading";
import Wrapper from "Components/Wrapper";
import { useQuery } from "react-query";
import { request, toLocaleDate } from "Utils";
import { useToast } from "Utils/hooks";
import Toast from "Components/Shared/toast";
import NoContentFound from "Components/Shared/noContentFound";
import StickyHeadTable from "Components/Shared/table";
import { KycRequestInt, KycRequestPageFunc } from "./type";
import KycMenu from "./menu";
import * as XLSX from "xlsx";
import { Button } from "@mui/material";

type TimePeriod =
  | "all"
  | "last7days"
  | "thisMonth"
  | "lastMonth"
  | "thisQuarter"
  | "lastQuarter";

const getKycRequests = async () => {
  const { data } = await request.get({ url: `/kyc-requests?showAll=true` });
  return data;
};

const KycRequestPageApproved: KycRequestPageFunc = () => {
  const { toggleToast, toastMessage } = useToast();
  const [pageId, setPageId] = useState(1);
  const [limit, setLimit] = useState(10);
  const [timePeriod, setTimePeriod] = useState<TimePeriod>("all");

  const { data, error, isLoading, refetch } = useQuery({
    queryKey: [],
    queryFn: getKycRequests,
    retry: 2,
  });
  const columns = [
    { minWidth: 20, name: "No", key: "index" },
    { minWidth: 50, name: "Id", key: "_id" },
    { minWidth: 50, name: "First Name", key: "first_name" },
    { minWidth: 50, name: "Last Name", key: "last_name" },
    { minWidth: 50, name: "Purpose", key: "purpose" },
    { minWidth: 50, name: "Created At", key: "createdAt" },
    { minWidth: 50, name: "Status", key: "status" },
    { minWidth: 50, name: "Action", key: "action" },
  ];

  const filterByTimePeriod = (
    kycRequests: KycRequestInt[],
    period: TimePeriod
  ): KycRequestInt[] => {
    const now = new Date();
    const periodStart = new Date();

    switch (period) {
      case "last7days":
        periodStart.setDate(now.getDate() - 7);
        break;
      case "thisMonth":
        periodStart.setDate(1);
        break;
      case "lastMonth":
        periodStart.setMonth(now.getMonth() - 1);
        periodStart.setDate(1);
        break;
      case "thisQuarter":
        periodStart.setMonth(Math.floor(now.getMonth() / 3) * 3);
        periodStart.setDate(1);
        break;
      case "lastQuarter":
        periodStart.setMonth(Math.floor(now.getMonth() / 3) * 3 - 3);
        periodStart.setDate(1);
        break;
      default:
        return kycRequests;
    }

    return kycRequests.filter(
      (request) => new Date(request.createdAt) >= periodStart
    );
  };

  const filteredKycRequests = useMemo(() => {
    if (!data) return [];
    const approvedRequests = data.filter(
      (kycRequest: KycRequestInt) => kycRequest?.status === "APPROVED"
    );
    return filterByTimePeriod(approvedRequests, timePeriod);
  }, [data, timePeriod]);

  const kycRequests = filteredKycRequests.map(
    (kycRequest: KycRequestInt, index: number) => ({
      index: ++index + limit * (pageId - 1),
      ...kycRequest,
      first_name: kycRequest?.userId?.firstname,
      last_name: kycRequest?.userId?.lastname,
      createdAt: toLocaleDate(kycRequest?.createdAt),
      status: kycRequest?.status,
      action: (
        <KycMenu
          userId={kycRequest?.userId?._id}
          kycRequest={kycRequest}
          toggleToast={toggleToast}
          refetch={refetch}
        />
      ),
    })
  );

  const downloadExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(
      filteredKycRequests.map((request) => ({
        ID: request._id,
        "First Name": request.userId?.firstname,
        "Last Name": request.userId?.lastname,
        Purpose: request.purpose,
        "Created At": toLocaleDate(request.createdAt),
        Status: request.status,
      }))
    );
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Approved KYC Requests");
    XLSX.writeFile(workbook, "approved_kyc_requests.xlsx");
  };

  if (isLoading) return <Loading />;
  if (error) return <ErrorPage error={error} />;

  return (
    <Wrapper>
      <Box sx={{ height: "85.5vh", position: "relative" }}>
        <Box
          sx={{
            mb: 2,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <FormControl sx={{ minWidth: 120 }}>
            <InputLabel id="time-period-label">Time Period</InputLabel>
            <Select
              labelId="time-period-label"
              value={timePeriod}
              onChange={(e) => setTimePeriod(e.target.value as TimePeriod)}
              label="Time Period"
            >
              <MenuItem value="all">All Time</MenuItem>
              <MenuItem value="last7days">Last 7 Days</MenuItem>
              <MenuItem value="thisMonth">This Month</MenuItem>
              <MenuItem value="lastMonth">Last Month</MenuItem>
              <MenuItem value="thisQuarter">This Quarter</MenuItem>
              <MenuItem value="lastQuarter">Last Quarter</MenuItem>
            </Select>
          </FormControl>

          <Button variant="contained" color="primary" onClick={downloadExcel}>
            Download Excel
          </Button>
        </Box>
        {kycRequests?.length ? (
          <Box pb={5}>
            <StickyHeadTable
              refetch={refetch}
              data={kycRequests}
              dataLength={data?.totalCount}
              columns={columns}
              setLimit={setLimit}
              limit={limit}
              setPageId={setPageId}
              pageId={pageId}
              pageCount={data.pageCount}
            />
          </Box>
        ) : (
          <NoContentFound />
        )}

        {Boolean(toastMessage) && (
          <Toast
            open={Boolean(toastMessage)}
            message={toastMessage}
            onClose={() => toggleToast("")}
          />
        )}
      </Box>
    </Wrapper>
  );
};
export default KycRequestPageApproved;

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Chart, ReactGoogleChartEvent } from "react-google-charts";
import { OfferStatsFunc } from "./types";
import { useNavigate } from "react-router-dom";

export const options = {
  chart: {
    title: "Total Offer Statistic",
    subtitle: "total amount requested in various currency",
  },
};

const OfferStatistics: OfferStatsFunc = ({ offerStats }) => {
  const navigate = useNavigate();
  const labels = offerStats?.map(({ currency, totalOffers }) => [
    currency,
    totalOffers,
  ]);

  const data = [["Currency", "Total"], ...labels];
  const readyEvent: ReactGoogleChartEvent[] = [
    {
      eventName: "select",
      callback: async ({ chartWrapper }: any) => {
        const currency = chartWrapper.getChart().getSelection();
        navigate(
          `/offers-statistics?status=OPEN&currency=${
            offerStats[currency[0].row].currency
          }`,
        );
      },
    },
  ];

  return (
    <>
      <Card sx={{ mt: 5.5, boxShadow: "0px 1px 70px 1px #f1f1f1" }}>
        <CardContent sx={{ textAlign: "center", position: "relative" }}>
          <Chart
            chartType="Bar"
            width="100%"
            height="400px"
            data={data}
            options={options}
            chartEvents={readyEvent}
          />
        </CardContent>
      </Card>
    </>
  );
};

export default OfferStatistics;

import DialogComponent from "Components/Shared/dailog";
import { Box, Typography, Grid } from "@mui/material";
import { ExchangeFeePaymentfunc } from "./type";

const ExchangeFeePayment: ExchangeFeePaymentfunc = ({
    isOpen,
    closeDialog,
    bdcInfo
}) => {
    const exchangeFeeBalance = bdcInfo?.exchangeFeeBalance || {};

    return (
        <div>
            <DialogComponent
                open={isOpen}
                onClose={closeDialog}
                title="Exchange Fee Balance"
                maxWidth="md"
            >
                <Box p={2}>
                    {Object.keys(exchangeFeeBalance).length === 0 ? (
                        <Typography variant="h5">No exchange fee balance available.</Typography>
                    ) : (
                        <Grid container spacing={2}>
                            {Object.entries(exchangeFeeBalance).map(([key, value]) => (
                                <Grid item xs={6} md={4} key={key}>
                                    <Box border={1} borderRadius={2} p={2}>
                                        <Typography variant="subtitle1" sx={{ fontSize: '20px', fontWeight: 'bolder' }}>{key}</Typography>
                                        <Typography variant="body1">Balance: {value?.balance}</Typography>
                                        <Typography variant="body1">Currency: {value?.currency}</Typography>
                                        <Typography variant="body1">Country: {value?.iso2}</Typography>
                                        <Typography variant="body1">Updated: {new Date(value?.updatedAt).toLocaleDateString()}</Typography>
                                    </Box>
                                </Grid>
                            ))}
                        </Grid>
                    )}
                </Box>
            </DialogComponent>
        </div>
    );
};

export default ExchangeFeePayment;

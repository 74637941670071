import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import { useDailog, useForm } from "Utils/hooks";
import ActionButton from "Components/Shared/button";
import { getQueryString } from "Utils";
import DialogComponent from "Components/Shared/dailog";
import { PaymentFilterFunc } from "./type";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const PaymentFilter: PaymentFilterFunc = () => {
  const { isOpen, closeDialog, openDialog } = useDailog();
  const { getData, values, submit } = useForm(submitForm);

  const navigate = useNavigate();
  const query = useLocation();

  useEffect(() => {
    let params;
    params = query.search?.split("?")[1];
    if (params) {
      params = params?.split("&");
      for (const item of params) {
        const key = item.split("=");
        values[key[0]] = key[1];
      }
    }
  }, [query.search, values]);

  function submitForm() {
    navigate(`/school-fee-payment${getQueryString(values)}`, { replace: true });
  }

  const status = ["PENDING", "SUCCESSFUL"];

  return (
    <div>
      <MenuOutlinedIcon
        onClick={openDialog}
        sx={{ fontSize: 40, mb: 2, cursor: "pointer" }}
      />
      <DialogComponent
        open={isOpen}
        onClose={closeDialog}
        title="Filter"
        maxWidth="sm"
      >
        <form onSubmit={submit}>
          <Stack spacing={3} py={3}>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between"
              }}
            >
              <FormControl sx={{ width: { md: "100%" }, mb: 3 }}>
                <InputLabel>Payment Status</InputLabel>
                <Select
                  name="status"
                  labelId="status"
                  label="Select status"
                  onChange={(e: any) => getData(e)}
                  defaultValue={values.status}
                >
                  {status?.map((status, index) => (
                    <MenuItem key={`${index}-status`} value={status}>
                      {status}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <ActionButton
                sx={{ width: { xs: "100%", md: "30%" }, mb: 3 }}
                type="submit"
                variant="contained"
              >
                Filter
              </ActionButton>
            </Box>
          </Stack>
        </form>
      </DialogComponent>
    </div>
  );
};
export default PaymentFilter;

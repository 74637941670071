import { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import IconButton from "@mui/material/IconButton";
import ArrowDownward from "@mui/icons-material/ArrowDownward";
import ArrowUpward from "@mui/icons-material/ArrowUpward";

interface Column {
  name: string;
  key: string;
  minWidth?: number;
  align?: "center" | "left" | "right" | "inherit";
  format?: any;
}

type SortDirection = "asc" | "desc";

export default function StickyHeadTable({
  refetch,
  data,
  dataLength,
  setLimit,
  columns,
  limit,
  setPageId,
  pageId,
  onClick,
  pageCount,
}: {
  data: Array<any>;
  columns: Array<Column>;
  dataLength?: any;
  limit?: any;
  setPageId?: Function;
  pageId?: any;
  refetch?: Function;
  setLimit?: Function;
  onClick?: Function;
  pageCount?: any;
}) {
  // const [isHovered, setIsHovered] = useState(false);
  const [sortConfig, setSortConfig] = useState<{
    key: string;
    direction: SortDirection;
  }>({ key: "", direction: "asc" });
  const [sortedData, setSortedData] = useState<Array<any>>(data);

  useEffect(() => {
    setSortedData(data);
  }, [data]);

  const handleSort = (key: string) => {
    let direction: SortDirection = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
    const sorted = [...sortedData].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === "asc" ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === "asc" ? 1 : -1;
      }
      return 0;
    });
    setSortedData(sorted);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPageId && setPageId(newPage + 1);
    refetch && refetch();
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newLimit =
      event.target.value === "-1"
        ? dataLength
        : parseInt(event.target.value, 10);
    setLimit && setLimit(newLimit);
    setPageId && setPageId(1); // Reset to first page when rows per page change
    refetch && refetch();
  };

  return (
    <Paper
      sx={{
        width: "100%",
        overflow: "hidden",
        boxShadow: "0px 1px 70px 1px #f1f1f1",
      }}
    >
      <TableContainer
        id="table-container"
        // onMouseEnter={() => setIsHovered(true)}
        // onMouseLeave={() => setIsHovered(false)}
        sx={{
          maxHeight: `calc(100vh - 200px)`,
          overflow: "scroll",
          transition: "overflow 0.3s ease-in-out",
          scrollbarWidth: "thin",
          WebkitOverflowScrolling: "touch",
          "::-webkit-scrollbar": {
            width: "1px",
          },
          "::-webkit-scrollbar-thumb": {
            backgroundColor: "#eb2e67",
            borderRadius: "4px",
          },
        }}
      >
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column, index) => (
                <TableCell
                  key={`${index}-${column.name}-col`}
                  align={column.align}
                  style={{
                    minWidth: column.minWidth,
                    background: "#dbdbdb",
                    fontSize: 14,
                    fontWeight: 600,
                  }}
                >
                  {column.name}
                  {column.key === "referralUsername" && (
                    <IconButton onClick={() => handleSort(column.key)}>
                      {sortConfig.key === column.key &&
                      sortConfig.direction === "asc" ? (
                        <ArrowUpward
                          sx={{ marginBottom: "5px" }}
                          fontSize="medium"
                        />
                      ) : (
                        <ArrowDownward
                          sx={{ marginBottom: "5px" }}
                          fontSize="medium"
                        />
                      )}
                    </IconButton>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData?.map((item, index) => {
              return (
                <TableRow
                  sx={{ cursor: onClick ? "pointer" : "" }}
                  onClick={() => onClick && onClick(item)}
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={`${index}-table-row`}
                >
                  {columns.map((column) => {
                    const value: any = item[column.key];
                    return (
                      <TableCell key={column.name} align={column.align}>
                        {value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={dataLength || 0}
        page={pageId - 1} // Adjust for zero-based page indexing
        onPageChange={handleChangePage}
        rowsPerPage={limit}
        rowsPerPageOptions={[
          5,
          10,
          25,
          50,
          { label: "ALL", value: dataLength },
        ]}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}

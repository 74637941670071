import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

export default function CheckboxComponents({
  label,
  required,
  onClick,
  sx,
}: {
  label: string;
  required?: boolean;
  onClick?: Function;
  sx?: {};
}) {
  return (
    <FormControlLabel
      sx={sx}
      required={required}
      control={<Checkbox onClick={() => onClick && onClick()} />}
      label={label}
    />
  );
}

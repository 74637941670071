import Stack from "@mui/material/Stack";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import EditOutlined from "@mui/icons-material/EditOutlined";

import InputField from "Components/Shared/textField";
import { useDailog, useForm } from "Utils/hooks";
import ActionButton from "Components/Shared/button";
import { request } from "Utils";
import { useState } from "react";
import Progress from "Components/Shared/circleLoading";
import DialogComponent from "Components/Shared/dailog";
import { useMutation } from "react-query";
import { UpdateConfigFunc } from "./type";

const UpdateConfig: UpdateConfigFunc = ({
  toggleToast,
  refetch,
  configuration,
  id,
}) => {
  const { isOpen, closeDialog, openDialog } = useDailog();
  const { getData, values, submit } = useForm(submitForm);
  const [isLoading, setIsloading] = useState(false);
  const types = ["PERCENTAGE", "FIXED", "TEXT", "BOOLEAN"];

  const mutation = useMutation(
    async () => {
      setIsloading(true);
      return await request.patch({
        url: `/configuration/${id}/update/${configuration.configTypeId}`,
        data: { ...values },
      });
    },
    {
      onSuccess: ({ message }) => {
        toggleToast(message);
        refetch();
        setIsloading(false);
        closeDialog();
      },
      onError: ({ message }) => {
        toggleToast(message);
        setIsloading(false);
      },
    },
  );
  function submitForm() {
    mutation.mutate();
  }

  return (
    <div>
      <IconButton onClick={openDialog} sx={{ ml: 2 }}>
        <EditOutlined sx={{ color: "#999999", fontSize: 20 }} />
      </IconButton>
      <DialogComponent
        open={isOpen}
        onClose={closeDialog}
        title={`Update ${configuration.configName}`}
        maxWidth="sm"
      >
        <form onSubmit={submit}>
          <Stack spacing={4} py={3}>
            <FormControl fullWidth>
              <InputLabel>Select Type *</InputLabel>
              <Select
                name="type"
                labelId="type"
                label="Select Type"
                value={values.type || configuration.type}
                onChange={(e: any) => getData(e)}
              >
                {types?.map((type, index) => (
                  <MenuItem key={`${index}-type`} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <InputField
              fullWidth
              type="text"
              label="Value"
              name="value"
              defaultValue={configuration.value}
              onChange={getData}
            />

            <ActionButton type="submit" variant="contained">
              Update Configuration
              {isLoading && (
                <Progress sx={{ color: "#fff", ml: 0.8 }} size={18} />
              )}
            </ActionButton>
          </Stack>
        </form>
      </DialogComponent>
    </div>
  );
};

export default UpdateConfig;

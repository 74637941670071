import Box from "@mui/material/Box";

import ActionButton from "Components/Shared/button";
import { request } from "Utils";
import { useState } from "react";
import Progress from "Components/Shared/circleLoading";
import DialogComponent from "Components/Shared/dailog";
import { useMutation } from "react-query";
import { Typography } from "@mui/material";
import { RestPasswordFunc } from "./type";

const RestPassword: RestPasswordFunc = ({
  toggleToast,
  isOpen,
  closeDialog,
  url,
  refetch,
}) => {
  const [isLoading, setIsloading] = useState(false);

  const mutation = useMutation(
    async () => {
      setIsloading(true);
      return await request.patch({ url });
    },
    {
      onSuccess: ({ message }) => {
        toggleToast(message);
        refetch && refetch();
        setIsloading(false);
        closeDialog();
      },
      onError: ({ message }) => {
        toggleToast(message);
        setIsloading(false);
      },
    },
  );
  return (
    <div>
      <DialogComponent
        open={isOpen}
        onClose={closeDialog}
        title="Reset account"
        maxWidth="xs"
      >
        <Box py={2}>
          <Typography variant="body1" sx={{ fontSize: 16, lineHeight: 1.6 }}>
            Are you sure you want to reset your password?
          </Typography>
          <Box textAlign="right">
            <ActionButton
              color="primary"
              onClick={() => mutation.mutate()}
              sx={{ fontSize: 16 }}
            >
              Yes, reset password{" "}
              {isLoading && (
                <Progress sx={{ color: "#fff", ml: 0.8 }} size={18} />
              )}
            </ActionButton>
            <ActionButton
              onClick={() => closeDialog()}
              sx={{ color: "#616161", fontSize: 16 }}
            >
              No, Cancel
            </ActionButton>
          </Box>
        </Box>
      </DialogComponent>
    </div>
  );
};

export default RestPassword;

import IconButton from "@mui/material/IconButton";
import DeleteForever from "@mui/icons-material/DeleteForeverOutlined";

import { useDailog } from "Utils/hooks";
import ConfirmDelete from "Components/Shared/confirmDelete";
import { DeleteConfigurationFunc } from "./type";

const DeleteConfiguration: DeleteConfigurationFunc = ({
  toggleToast,
  url,
  refetch,
}) => {
  const confirmDeleteModal = useDailog();

  return (
    <>
      <IconButton onClick={confirmDeleteModal.openDialog}>
        <DeleteForever style={{ color: "red", fontSize: 20 }} />
      </IconButton>
      <ConfirmDelete
        isOpen={confirmDeleteModal.isOpen}
        closeDialog={confirmDeleteModal.closeDialog}
        toggleToast={toggleToast}
        url={url}
        refetch={refetch}
      />
    </>
  );
};
export default DeleteConfiguration;

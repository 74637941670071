import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Hidden from "@mui/material/Hidden";
import Container from "@mui/material/Container";
import SideDrawer from "./drawer";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { getUserData, session } from "Utils";
import { HeaderFunc } from "./type";
import { useEffect, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IconButton } from "@mui/material";

const Header: HeaderFunc = () => {
  const user = session.get("user");
  const locHalf = useLocation().pathname.split("/")[1];
  const mainPath = useLocation().pathname;
  const [getQuery] = useSearchParams();
  let status = getQuery.get("status");
  const navigate = useNavigate();

  const [userData, setUserData] = useState({
    firstname: "",
    lastname: "",
  });

  useEffect(() => {
    var pathArrString = localStorage.getItem("pathArr");
    var pathArr: string[] = pathArrString ? JSON.parse(pathArrString) : [];
    var mainArr = [...pathArr, mainPath];
    if (mainPath === "/users") {
      localStorage.removeItem("userId");
    }
    localStorage.setItem("pathArr", JSON.stringify(mainArr));

    let userId = localStorage.getItem("userId");
    if (userId !== null && pathArr[pathArr.length - 1] === "/users") {
      getUserData(localStorage.getItem("userId") ?? "")
        .then((data) => {
          setUserData({ firstname: data.firstname, lastname: data.lastname });
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setUserData({ firstname: "", lastname: "" });
    }
  }, [mainPath]);

  const [loc, setLoc] = useState(locHalf);

  useEffect(() => {
    if (status === "OPEN") {
      setLoc(`Open ${locHalf}`);
    } else if (status === "SOLD") {
      setLoc(`Fullfilled ${locHalf}`);
    } else if (status === "CANCELLED") {
      setLoc(`Cancelled ${locHalf}`);
    } else if (status === "FAILED") {
      setLoc(`Failed ${locHalf}`);
    } else if (status === "SUCCESSFUL") {
      setLoc(`Successful ${locHalf}`);
    } else if (status === "PENDING") {
      setLoc(`Pending ${locHalf}`);
    }
  }, [locHalf, status]);

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <Box
      sx={{ position: "fixed", width: { xs: "100%", md: "88%" }, zIndex: 10 }}
    >
      <AppBar position="static" sx={{ background: "#fff", boxShadow: "none" }}>
        <Toolbar>
          <Container maxWidth="xl" sx={{ px: "0px !important" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "15px",
                }}
              >
                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="back"
                  onClick={handleGoBack}
                  sx={{
                    display: mainPath === "/" ? "none" : "block",
                    color: "#616161",
                    marginTop: "7px",
                  }}
                >
                  <ArrowBackIcon
                    style={{ fontSize: 35 }}
                    sx={{ "&:hover": { cursor: "pointer", color: "purple" } }}
                  />
                </IconButton>
                <Typography
                  variant="h5"
                  fontWeight={700}
                  component="div"
                  sx={{ textTransform: "capitalize" }}
                >
                  {loc ? loc : "Dashboard"}{" "}
                  {userData.firstname !== "" && (
                    <>
                      {" "}
                      {loc === "send-mail" ? "to" : "of"} {userData.firstname}{" "}
                      {userData.lastname}
                    </>
                  )}
                </Typography>
              </Box>

              <Typography
                variant="h6"
                fontWeight={500}
                component="div"
                sx={{ textTransform: "capitalize", color: "#616161" }}
              >
                {user.fullName}
              </Typography>

              <Hidden mdUp>
                <SideDrawer />
              </Hidden>
            </Box>
          </Container>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default Header;

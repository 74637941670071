import Stack from "@mui/material/Stack";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";

import { useDailog, useForm } from "Utils/hooks";
import ActionButton from "Components/Shared/button";
import { handleError, request } from "Utils";
import DialogComponent from "Components/Shared/dailog";
import { ChangeWalletStatusFunct } from "./type";
import { useMutation } from "react-query";
import { useState } from "react";
import Progress from "Components/Shared/circleLoading";

const ChangeWalletStatus: ChangeWalletStatusFunct = ({
  walletId,
  toggleToast,
  refetch,
  defaultStatus,
  pocketId,
}) => {
  const { isOpen, closeDialog, openDialog } = useDailog();
  const { getData, values, submit } = useForm(submitForm);
  const [isLoading, setIsloading] = useState(false);

  const mutation = useMutation(
    async () => {
      setIsloading(true);
      return await request.patch({
        url: `/wallet/${walletId}/pocket/${pocketId}`,
        data: values,
      });
    },
    {
      onSuccess: ({ message }) => {
        toggleToast(message);
        refetch();
        setIsloading(false);
        closeDialog();
      },
      onError: (err) => {
        toggleToast(handleError(err).message);
        setIsloading(false);
      },
    },
  );
  function submitForm() {
    mutation.mutate();
  }

  const status = [
    "ACTIVE",
    "BLOCKED",
    "SUSPENDED",
    "UNVERIFIED",
    "DEACTIVATED",
  ];

  return (
    <div>
      <MenuItem onClick={openDialog}>Change Wallet Status </MenuItem>
      <DialogComponent
        open={isOpen}
        onClose={closeDialog}
        title="Change Wallet Status"
        maxWidth="sm"
      >
        <form onSubmit={submit}>
          <Stack spacing={3} py={3}>
            <FormControl fullWidth>
              <InputLabel>Select Status</InputLabel>
              <Select
                name="status"
                labelId="status"
                label="Select status"
                value={values.status || defaultStatus}
                onChange={(e: any) => getData(e)}
              >
                {status?.map((status, index) => (
                  <MenuItem key={`${index}-status`} value={status}>
                    {status}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <ActionButton type="submit" variant="contained">
              Change Wallet Status
              {isLoading && (
                <Progress sx={{ color: "#fff", ml: 0.8 }} size={18} />
              )}
            </ActionButton>
          </Stack>
        </form>
      </DialogComponent>
    </div>
  );
};

export default ChangeWalletStatus;

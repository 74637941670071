import Menus from "Components/Shared/menu";
import IconButton from "@mui/material/IconButton";
import EditOutlined from "@mui/icons-material/EditOutlined";
import LockResetIcon from "@mui/icons-material/LockReset";
import SettingsSuggestOutlinedIcon from "@mui/icons-material/SettingsSuggestOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import DeleteForever from "@mui/icons-material/DeleteForeverOutlined";
import MenuItem from "@mui/material/MenuItem";
import MoreVert from "@mui/icons-material/MoreVert";
import { useDailog, useMenu } from "Utils/hooks";

import UpdateKYCtype from "./update";
import ConfirmDelete from "Components/Shared/confirmDelete";
import RestAccountStatus from "./resetAccount";
import RestRole from "./resetRole";
import RestPassword from "./resetPassword";
import ChangePassword from "./changePassword";
import { UsermenuFunc } from "./type";

const Usermenu: UsermenuFunc = (props) => {
  const { isOpen, closeDialog, openDialog } = useDailog();
  const confirmDeleteModal = useDailog();
  const resetAccountModal = useDailog();
  const roleModal = useDailog();
  const resetPasswordModal = useDailog();
  const changePasswordModal = useDailog();
  const { anchorEl, menuIsOpen, closeMenu, openMenu } = useMenu();
  return (
    <>
      <IconButton onClick={openMenu}>
        <MoreVert />
      </IconButton>
      <Menus anchorEl={anchorEl} open={menuIsOpen} closeMenu={closeMenu}>
        <div>
          {props.isSupperAdmin && (
            <>
              <MenuItem disableRipple onClick={resetAccountModal.openDialog}>
                <LockResetIcon /> Reset status
              </MenuItem>
              <MenuItem disableRipple onClick={roleModal.openDialog}>
                <SettingsSuggestOutlinedIcon /> Change role
              </MenuItem>
              <MenuItem disableRipple onClick={resetPasswordModal.openDialog}>
                <LockResetIcon /> Reset password
              </MenuItem>
            </>
          )}
          <MenuItem disableRipple onClick={changePasswordModal.openDialog}>
            <VisibilityOutlinedIcon /> Change password
          </MenuItem>
          <MenuItem disableRipple onClick={openDialog}>
            <EditOutlined /> Update
          </MenuItem>
          {props.isSupperAdmin && (
            <MenuItem
              sx={{ color: "red" }}
              disableRipple
              onClick={confirmDeleteModal.openDialog}
            >
              <DeleteForever style={{ color: "red" }} /> Delete
            </MenuItem>
          )}
        </div>
      </Menus>
      <UpdateKYCtype
        refetch={props.refetch}
        isOpen={isOpen}
        closeDialog={closeDialog}
        toggleToast={props.toggleToast}
        admin={props.admin}
      />
      <ChangePassword
        refetch={props.refetch}
        isOpen={changePasswordModal.isOpen}
        closeDialog={changePasswordModal.closeDialog}
        toggleToast={props.toggleToast}
      />
      <RestAccountStatus
        refetch={props.refetch}
        isOpen={resetAccountModal.isOpen}
        closeDialog={resetAccountModal.closeDialog}
        toggleToast={props.toggleToast}
        url={`/admin/${props.admin?.id}/reset-account`}
        status={props.admin.status}
      />
      <RestPassword
        refetch={props.refetch}
        isOpen={resetPasswordModal.isOpen}
        closeDialog={resetPasswordModal.closeDialog}
        toggleToast={props.toggleToast}
        url={`/admin/${props.admin?.id}/reset-password`}
      />
      <RestRole
        refetch={props.refetch}
        isOpen={roleModal.isOpen}
        closeDialog={roleModal.closeDialog}
        toggleToast={props.toggleToast}
        url={`/admin/${props?.admin?.id}/reset-account`}
        roleId={props?.admin?.roleId?._id}
      />
      <ConfirmDelete
        refetch={props.refetch}
        isOpen={confirmDeleteModal.isOpen}
        closeDialog={confirmDeleteModal.closeDialog}
        toggleToast={props.toggleToast}
        url={`/admin/${props?.admin?.id}`}
      />
    </>
  );
};
export default Usermenu;

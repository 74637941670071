import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Rolemenu from "Components/Roles/menu";
import { RoleCardFunc } from "./type";

const RoleCard: RoleCardFunc = ({ name, id, refetch, toggleToast }) => {
  return (
    <Card sx={{ padding: 0, boxShadow: "0px 1px 70px 1px #f1f1f1" }}>
      <CardContent sx={{ padding: 0, margin: 0 }}>
        <Box>
          <Rolemenu
            refetch={refetch}
            toggleToast={toggleToast}
            name={name}
            id={id}
          />
        </Box>
        <Box sx={{ textAlign: "center" }} mt={3} py={3}>
          <Typography variant="h4" style={{ color: "#333333" }}>
            {name}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};
export default RoleCard;

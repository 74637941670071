import { request } from "Utils";
import { LoginFunc, UserInt } from "./type";

export const Login: LoginFunc = async (fields) => {
  const { data } = await request.post({
    url: "/admin/login",
    data: fields,
  });

  return data as UserInt;
};

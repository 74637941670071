import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

import ErrorPage from "Components/Shared/error";
import Loading from "Components/Shared/loading";
import Wrapper from "Components/Wrapper";
import { useQuery } from "react-query";
import { request } from "Utils";
import NoContentFound from "Components/Shared/noContentFound";
import AddConfig from "./add";
import { useToast } from "Utils/hooks";
import Toast from "Components/Shared/toast";
import CountryCard from "Components/Shared/cards/config";
import { ConfigurationFunc, CountryInt } from "./type";

const getConfigurations = async ({ queryKey }: any) => {
  const [, pageId, limit] = queryKey;
  const { data } = await request.get({
    url: `/configurations?pageId=${pageId + 1}&limit=${limit}`,
  });
  return data;
};

const Configuration: ConfigurationFunc = () => {
  const { toggleToast, toastMessage } = useToast();
  const { data, error, isLoading, refetch } = useQuery({
    queryKey: ["Users", 1, 50],
    queryFn: getConfigurations,
    retry: 2,
    // staleTime: 86400000,
  });

  return (
    <Wrapper>
      <>
        {isLoading ? (
          <Loading />
        ) : error ? (
          <ErrorPage error={error} />
        ) : data?.length ? (
          <Box pb={5} sx={{}}>
            <Grid container spacing={2}>
              {data?.map((config: CountryInt, index: number) => (
                <Grid item xs={12} sm={6} md={4} lg={3} key={`country${index}`}>
                  <CountryCard
                    config={config}
                    toggleToast={toggleToast}
                    refetch={refetch}
                    key={index}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
        ) : (
          <NoContentFound />
        )}
        <AddConfig refetch={refetch} toggleToast={toggleToast} />

        {Boolean(toastMessage) && (
          <Toast
            open={Boolean(toastMessage)}
            message={toastMessage}
            onClose={() => toggleToast("")}
          />
        )}
      </>
    </Wrapper>
  );
};

export default Configuration;

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import ConfirmDelete from "Components/Shared/confirmDelete";
import { useDailog } from "Utils/hooks";
import { theme } from "Styles/theme";
import { CardFunc } from "./type";

const KYCCard: CardFunc = ({
  name,
  type,
  verifier,
  _id,
  id,
  toggleToast,
  refetch,
  index,
}) => {
  const { isOpen, closeDialog, openDialog } = useDailog();
  function done() {
    refetch(index);
    closeDialog();
  }
  return (
    <>
      <Card
        sx={{ cursor: "pointer", borderTop: "none !important" }}
        onClick={openDialog}
      >
        <CardContent sx={{ textAlign: "center", p: "0px !important" }}>
          <Typography
            variant="h5"
            sx={{ pt: 2, fontWeight: 700 }}
            color="text.secondary"
            gutterBottom
          >
            {name}
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{ mb: 1.5 }}
            color="text.secondary"
          >
            {type}
          </Typography>
          <Typography
            variant="body1"
            fontWeight={700}
            sx={{
              py: 1,
              background: theme.palette.primary.main,
              color: "#fff",
            }}
          >
            {verifier}
          </Typography>
        </CardContent>
      </Card>
      <ConfirmDelete
        isOpen={isOpen}
        closeDialog={closeDialog}
        toggleToast={toggleToast}
        url={_id ? `/wallet-kyc/${_id}` : `/kyc-type/${id}`}
        refetch={done}
      />
    </>
  );
};

export default KYCCard;

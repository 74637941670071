import Box from "@mui/material/Box";

import ErrorPage from "Components/Shared/error";
import Loading from "Components/Shared/loading";
import StickyHeadTable from "Components/Shared/table";
import Wrapper from "Components/Wrapper";
import { useState } from "react";
import { useQuery } from "react-query";
import { request } from "Utils";
import { useToast } from "Utils/hooks";
import Toast from "Components/Shared/toast";
import TransactionMenu from "./menu";
import { TransactionInt, TransactionsFunc } from "./type";
import { useParams } from "react-router-dom";
import NoContentFound from "Components/Shared/noContentFound";

const getWallets = async ({ queryKey }: any) => {
  const [, offerId] = queryKey;
  const { data } = await request.get({ url: `/transaction/offer/${offerId}` });
  return data;
};

const OfferTransactions: TransactionsFunc = () => {
  const { offerId } = useParams();
  const { toggleToast, toastMessage } = useToast();
  const [pageId, setPageId] = useState(1);
  const [limit, setLimit] = useState(10);
  const { data, error, isLoading, refetch } = useQuery({
    queryKey: ["Users", offerId],
    queryFn: getWallets,
    retry: 2,
    // staleTime: 86400000,
  });
  const columns = [
    { minWidth: 20, name: "No", key: "index" },
    { minWidth: 50, name: "Amount", key: "amount" },
    { minWidth: 50, name: "Currency", key: "currency" },
    { minWidth: 50, name: "Fee", key: "fee" },
    { minWidth: 150, name: "Payment Gateway", key: "paymentGateway" },
    { minWidth: 50, name: "Payment Method", key: "paymentMethod" },
    { minWidth: 50, name: "Purpose", key: "purpose" },
    { minWidth: 100, name: "Sender", key: "senderName" },
    { minWidth: 100, name: "Receiver", key: "receiverName" },
    { minWidth: 50, name: "Status", key: "status" },
    { minWidth: 50, name: "Type", key: "type" },
    { minWidth: 50, name: "Action", key: "action" },
  ];
  const transactions = data?.transactions?.map(
    (transaction: TransactionInt, index: number) => ({
      index: ++index + limit * (pageId - 1),
      ...transaction,
      senderName: transaction.metadata?.sender?.name || "N/A",
      receiverName: transaction.metadata?.receiver?.name || "N/A",
      action: (
        <TransactionMenu
          transactionId={transaction.id}
          walletId={transaction.walletId}
          toggleToast={toggleToast}
        />
      ),
    }),
  );
  if (isLoading) return <Loading />;
  if (error) return <ErrorPage error={error} />;
  return (
    <Wrapper>
      {data?.transactions?.length ? (
        <Box sx={{ height: "85.5vh", position: "relative" }}>
          <StickyHeadTable
            refetch={refetch}
            data={transactions}
            dataLength={data?.totalTransactions}
            columns={columns}
            setLimit={setLimit}
            limit={limit}
            setPageId={setPageId}
            pageId={pageId}
            pageCount={data.pageCount}
          />
          {Boolean(toastMessage) && (
            <Toast
              open={Boolean(toastMessage)}
              message={toastMessage}
              onClose={() => toggleToast("")}
            />
          )}
        </Box>
      ) : (
        <NoContentFound />
      )}
    </Wrapper>
  );
};

export default OfferTransactions;

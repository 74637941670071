import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { theme } from "Styles/theme";
import { KYCCardFunc } from "./type";

const KYCCard: KYCCardFunc = (props) => {
  return (
    <Card sx={{ padding: 0, width: 500 }}>
      <CardContent sx={{ padding: 0, margin: 0 }}>
        <Box>
          <Typography
            variant="body1"
            component="span"
            px={2}
            pb={1}
            style={{
              paddingTop: 20,
              color: "#ffffff",
              background: theme.palette.primary.main,
            }}
          >
            {props.isApproved ? "Approved" : "Not Approved"}
          </Typography>
        </Box>
        <Box sx={{ textAlign: "center" }} mt={3}>
          <Typography variant="h3" sx={{ fontSize: 14, color: "#333333" }}>
            {props.name}
          </Typography>
        </Box>

        <Box sx={{ textAlign: "start", padding: 5 }} mt={3}>
          {/* <div>
            <div style={{ paddingBottom: 10 }}>
              <div>
                Names: {props?.kycDetails?.firstName}{" "}
                {props?.kycDetails?.middleName} {props?.kycDetails?.lastName}
              </div>
              <div>Date of Birth: {props?.kycDetails?.dateOfBirth}</div>

              <div>Phone Number: {props?.kycDetails?.phoneNumber1}</div>
              <div>Email: {props?.kycDetails?.email}</div>
              <div>
                Residential Address: {props?.kycDetails?.residentialAddress}
              </div>
              <div>Account Level: {props?.kycDetails?.levelOfAccount}</div>
              <div>Lga of Origin: {props?.kycDetails?.lgaOfOrigin}</div>
              <div>State of Origin: {props?.kycDetails?.stateOfOrigin}</div>
              <div>Nationality: {props?.kycDetails?.nationality}</div>
            </div>
            <img
              height={200}
              src={`data:image/png;base64,${props?.kycDetails?.base64Image}`}
            />
          </div> */}
        </Box>
      </CardContent>
    </Card>
  );
};
export default KYCCard;

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import DialogComponent from "Components/Shared/dailog";
import KYCCard from "./card";
import Typography from "@mui/material/Typography";
import Loading from "Components/Shared/loading";
import { ListKYCrequestFunc } from "./type";

const KYCrequest: ListKYCrequestFunc = ({
  toggleToast,
  currency,
  isLoading,
  dialogModal,
  kycTypes,
  setKYCtypes,
}) => {
  const refetch = (index: number) => {
    kycTypes.splice(index, 1);
    setKYCtypes([...kycTypes]);
  };
  return (
    <div>
      <DialogComponent
        open={dialogModal.isOpen}
        onClose={dialogModal.closeDialog}
        title="List Kyc's Request"
      >
        {isLoading ? (
          <Loading />
        ) : (
          <Box my={4}>
            {kycTypes.length ? (
              <Box mt={5}>
                <Typography variant="h6" fontWeight={700} mb={2}>
                  {currency} KYC
                </Typography>
                <Grid container spacing={3}>
                  {kycTypes.map((kycType, index) => (
                    <Grid item md={4}>
                      <KYCCard
                        key={index}
                        {...kycType}
                        index={index}
                        refetch={refetch}
                        toggleToast={toggleToast}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            ) : (
              <Typography mt={4} textAlign="center">
                Select currency to list the KYC
              </Typography>
            )}
          </Box>
        )}
      </DialogComponent>
    </div>
  );
};
export default KYCrequest;

import { MoreVert } from "@mui/icons-material";
import { IconButton, MenuItem } from "@mui/material";
import Menus from "Components/Shared/menu";
import { useDailog, useMenu } from "Utils/hooks";
import AcUnitIcon from '@mui/icons-material/AcUnit';
import { BdcMenuFunc } from "./type";
import ExchangeFeePayment from "./exchangeFeeBalance";
import PaymentDetails from "./paymentDetails";


const BdcMenu : BdcMenuFunc = (props) => {
    const { anchorEl, menuIsOpen, closeMenu, openMenu } = useMenu();
    const { isOpen, openDialog, closeDialog } = useDailog()
    const paymentDialog = useDailog()


    return (
        <>
            <IconButton onClick={openMenu}>
                <MoreVert />
            </IconButton>

            <Menus anchorEl={anchorEl} open={menuIsOpen} closeMenu={closeMenu}>
                <div>
                 <MenuItem onClick={openDialog}>
                  <AcUnitIcon /> View Exchange Fee Balance
                 </MenuItem>
                 <MenuItem onClick={paymentDialog.openDialog}>
                  <AcUnitIcon /> View Payment Details
                 </MenuItem>
                </div>
            </Menus>

            <ExchangeFeePayment
             isOpen={isOpen}
             closeDialog={closeDialog}
             bdcInfo={props?.bdcInfo}
            />
            <PaymentDetails
             isOpen={paymentDialog.isOpen}
             closeDialog={paymentDialog.closeDialog}
             bdcInfo={props?.bdcInfo}
            />

        </>


    )
}

export default BdcMenu
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import { DashboardCardFunct } from "./types";

const DashboardCard: DashboardCardFunct = ({ icon, count, title, link }) => {
  return (
    <Link to={link}>
      <Card sx={{ boxShadow: "0px 1px 70px 1px #f1f1f1" }}>
        <CardContent sx={{ textAlign: "center" }}>
          <Typography
            variant="body1"
            sx={{ fontWeight: 700, fontSize: 30, color: "#333333" }}
            color="text.secondary"
            gutterBottom
          >
            {count}
          </Typography>

          <Typography
            variant="body2"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              textTransform: "uppercase",
            }}
            color="text.secondary"
          >
            {icon} {title}
          </Typography>
        </CardContent>
      </Card>
    </Link>
  );
};
export default DashboardCard;

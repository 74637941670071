import Stack from "@mui/material/Stack";
import InputField from "Components/Shared/textField";

import { useForm } from "Utils/hooks";
import ActionButton from "Components/Shared/button";
import { request } from "Utils";
import { useState } from "react";
import Progress from "Components/Shared/circleLoading";
import DialogComponent from "Components/Shared/dailog";
import { useMutation } from "react-query";
import { UpdateVASFunc } from "./type";

const UpdateVAS: UpdateVASFunc = ({
  toggleToast,
  isOpen,
  closeDialog,
  vas,
  refetch,
}) => {
  const { getData, values, submit } = useForm(submitForm);
  const [isLoading, setIsloading] = useState(false);
  const mutation = useMutation(
    async () => {
      setIsloading(true);
      if (values.providerDiscount < 0) {
        setIsloading(false);
        throw toggleToast("Providers Discount can not be less than 0");
      }
      if (values.discount < 0) {
        setIsloading(false);
        throw toggleToast("Discount can not be less than 0");
      }
      if (values.discount > values.providerDiscount) {
        setIsloading(false);
        throw toggleToast(
          "Discount can not be greater than the providers discount",
        );
      }
      values.discount = Number(values.discount);
      values.providerDiscount = Number(values.providerDiscount);

      return await request.patch({
        url: `/vas/${vas._id}`,
        data: values,
      });
    },
    {
      onSuccess: ({ message }) => {
        toggleToast(message);
        refetch();
        setIsloading(false);
        closeDialog();
      },
      onError: ({ message }) => {
        toggleToast(message);
        setIsloading(false);
      },
    },
  );
  function submitForm() {
    mutation.mutate();
  }

  return (
    <div>
      <DialogComponent
        open={isOpen}
        onClose={closeDialog}
        title={`Update ${vas.service}  VAS`}
        maxWidth="xs"
      >
        <form onSubmit={submit}>
          <Stack spacing={4} py={4}>
            <InputField
              type="number"
              label="In-house Discount"
              name="discount"
              defaultValue={vas.discount}
              onChange={getData}
              required
            />
            <InputField
              type="number"
              label="Provider Discount"
              defaultValue={vas.providerDiscount}
              name="providerDiscount"
              onChange={getData}
              required
            />

            <ActionButton type="submit" variant="contained">
              Update VAS{" "}
              {isLoading && (
                <Progress sx={{ color: "#fff", ml: 0.8 }} size={18} />
              )}
            </ActionButton>
          </Stack>
        </form>
      </DialogComponent>
    </div>
  );
};
export default UpdateVAS;

import Menus from "Components/Shared/menu";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import MoreVert from "@mui/icons-material/MoreVert";
import { useMenu } from "Utils/hooks";

import { Link } from "react-router-dom";
import { TransactionMenuFunc } from "./type";

const TransactionMenu: TransactionMenuFunc = (props) => {
  const { anchorEl, menuIsOpen, closeMenu, openMenu } = useMenu();
  return (
    <>
      <IconButton onClick={openMenu}>
        <MoreVert />
      </IconButton>
      <Menus anchorEl={anchorEl} open={menuIsOpen} closeMenu={closeMenu}>
        <div>
          <Link to={`/wallet?globalId=${props.walletId}`}>
            <MenuItem disableRipple>View Wallet</MenuItem>
          </Link>
          <Link to={`/transaction-details?id=${props.transactionId}`}>
            <MenuItem disableRipple>View Transaction</MenuItem>
          </Link>
        </div>
      </Menus>
    </>
  );
};

export default TransactionMenu;

import Stack from "@mui/material/Stack";
import InputField from "Components/Shared/textField";

import { useForm } from "Utils/hooks";
import ActionButton from "Components/Shared/button";
import { handleError, request } from "Utils";
import { useState } from "react";
import Progress from "Components/Shared/circleLoading";
import DialogComponent from "Components/Shared/dailog";
import { useMutation } from "react-query";
import { ConfigurationTypeUpdateFunc } from "./type";

const UpdateConfigType: ConfigurationTypeUpdateFunc = ({
  toggleToast,
  isOpen,
  closeDialog,
  configType,
  refetch,
}) => {
  const { getData, values, submit, reset } = useForm(submitForm);
  const [isLoading, setIsloading] = useState(false);

  const mutation = useMutation(
    async () => {
      setIsloading(true);
      return await request.patch({
        url: `/config-type/${configType.id}`,
        data: values,
      });
    },
    {
      onSuccess: ({ message }) => {
        reset();
        toggleToast(message);
        refetch();
        setIsloading(false);
        closeDialog();
      },
      onError: (err) => {
        toggleToast(handleError(err).message);
        setIsloading(false);
      },
    },
  );
  function submitForm() {
    mutation.mutate();
  }

  return (
    <div>
      <DialogComponent
        open={isOpen}
        onClose={closeDialog}
        title={`Update ${configType.name} config type`}
      >
        <form onSubmit={submit}>
          <Stack spacing={4} py={4}>
            <InputField
              type="text"
              label="KYC Name"
              name="name"
              defaultValue={configType.name}
              onChange={getData}
              required
            />

            <ActionButton type="submit" variant="contained">
              Update KYC{" "}
              {isLoading && (
                <Progress sx={{ color: "#fff", ml: 0.8 }} size={18} />
              )}
            </ActionButton>
          </Stack>
        </form>
      </DialogComponent>
    </div>
  );
};
export default UpdateConfigType;

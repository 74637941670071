import Button, { ButtonProps } from "@mui/material/Button";

export default function BasicButtons(props: ButtonProps) {
  const { children, sx, ...rest } = props;
  return (
    <Button {...rest} sx={{ fontSize: 18, px: 2.4, py: 1.2, ...sx }}>
      {children}
    </Button>
  );
}

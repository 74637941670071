import Menus from "Components/Shared/menu";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { useMenu } from "Utils/hooks";
import MomoTransfer from "./momoTransfer";
import BankTransfer from "./bankTransfer";

const NsanoMenu = ({ refetch }: any) => {
  const { anchorEl, menuIsOpen, closeMenu, openMenu } = useMenu();
  return (
    <>
      <IconButton onClick={openMenu}>
        <MenuIcon fontSize="large" />
      </IconButton>
      <Menus anchorEl={anchorEl} open={menuIsOpen} closeMenu={closeMenu}>
        <>
          <BankTransfer refetch={refetch} />
          <MomoTransfer refetch={refetch} />
        </>
      </Menus>
    </>
  );
};
export default NsanoMenu;

import Wrapper from "Components/Wrapper";
import { CountryInt, PayTuitionFunc } from "./type";
import { useDailog, useToast } from "Utils/hooks";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import FabButton from "Components/Shared/fab";
import AddCountry from "./add";
import { request } from "Utils";
import { useState } from "react";
import { useQuery } from "react-query";
import CountryMenu from "./menu";
import Loading from "Components/Shared/loading";
import ErrorPage from "Components/Shared/error";
import StickyHeadTable from "Components/Shared/table";
import NoContentFound from "Components/Shared/noContentFound";
import Toast from "Components/Shared/toast";

const getCountry = async () => {
  const { data } = await request.get({
    url: `/school-configs`
  });
  return data;
};

const PayTuition: PayTuitionFunc = () => {
  const { isOpen, openDialog, closeDialog } = useDailog();
  const { toggleToast, toastMessage } = useToast();
  const [pageId, setPageId] = useState(1);
  const [limit, setLimit] = useState(10);
  const { data, error, isLoading, refetch } = useQuery({
    queryKey: ["Users", pageId, limit],
    queryFn: getCountry,
    retry: 2
    // staleTime: 86400000,
  });

  const columns = [
    { minWidth: 20, name: "No", key: "index" },
    { minWidth: 50, name: "Country Name", key: "country" },
    { minWidth: 50, name: "Currency", key: "currency" },
    { minWidth: 50, name: "Limit", key: "limit" },
    { minWidth: 50, name: "Charges", key: "charges" },
    { minWidth: 50, name: "Action", key: "action" }
  ];

  const countries =
    data &&
    data?.map((country: CountryInt, index: number) => ({
      index: ++index + limit * (pageId - 1),
      ...country,
      action: (
        <CountryMenu
          country={country}
          refetch={refetch}
          toggleToast={toggleToast}
        />
      )
    }));
  if (isLoading) return <Loading />;
  if (error) return <ErrorPage error={error} />;
  return (
    <Wrapper>
      <Container maxWidth="md">
        {countries?.length ? (
          <Box pb={5} sx={{ height: countries?.length <= 7 ? "77vh" : "" }}>
            <StickyHeadTable
              refetch={refetch}
              data={countries}
              dataLength={data?.totalCount}
              columns={columns}
              setLimit={setLimit}
              limit={limit}
              setPageId={setPageId}
              pageId={pageId}
              pageCount={data.pageCount}
            />
          </Box>
        ) : (
          <NoContentFound />
        )}

        <AddCountry
          refetch={refetch}
          isOpen={isOpen}
          closeDialog={closeDialog}
          toggleToast={toggleToast}
        />
        <FabButton onClick={openDialog} />
        {Boolean(toastMessage) && (
          <Toast
            open={Boolean(toastMessage)}
            message={toastMessage}
            onClose={() => toggleToast("")}
          />
        )}
      </Container>
    </Wrapper>
  );
};

export default PayTuition;

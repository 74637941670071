import Box from "@mui/material/Box";

import ErrorPage from "Components/Shared/error";
import Loading from "Components/Shared/loading";
import StickyHeadTable from "Components/Shared/table";
import Wrapper from "Components/Wrapper";
import { useQuery } from "react-query";
import { useState } from "react";
import { handleError, request } from "Utils";
import AddVas from "./add";
import { useDailog, useToast } from "Utils/hooks";
import Toast from "Components/Shared/toast";
import FabButton from "Components/Shared/fab";
import KYCtableMenu from "./tableMenu";
import NoContentFound from "Components/Shared/noContentFound";
import { ServiceProps, VASINt, VASTypesFunc } from "./type";
import { useParams } from "react-router-dom";

const getVAS = async ({ queryKey }: any) => {
  const [, iso2] = queryKey;
  const { data } = await request.get({
    url: `/vas/list/${iso2}`,
  });
  return data;
};

const VASPage: VASTypesFunc = () => {
  const { toggleToast, toastMessage } = useToast();
  const { isOpen, closeDialog, openDialog } = useDailog();
  const [services, setServices] = useState<Array<ServiceProps>>();
  const [loading, setLoading] = useState(false);
  let { iso2 } = useParams();
  const { data, error, isLoading, refetch } = useQuery({
    queryKey: ["vas", iso2],
    queryFn: getVAS,
    retry: 2,
    // staleTime: 86400000,
  });
  async function getServices() {
    try {
      openDialog();
      setLoading(true);
      const { data } = await request.get({ url: "/vas", method: "get" });
      console.log(data);
      setServices(data);
      setLoading(false);
    } catch (error) {
      toggleToast(handleError(error).message);
    }
  }
  const columns = [
    { minWidth: 20, name: "No", key: "index" },
    { minWidth: 50, name: "Service", key: "service" },
    { minWidth: 50, name: "Monirate discount(%)", key: "discount" },
    {
      minWidth: 50,
      name: "Service provider discount(%)",
      key: "providerDiscount",
    },
    { minWidth: 50, name: "Action", key: "action" },
  ];
  const vasTypes =
    data &&
    data?.length &&
    data?.map((vas: VASINt, index: number) => ({
      index: ++index,
      ...vas,
      action: (
        <KYCtableMenu vas={vas} refetch={refetch} toggleToast={toggleToast} />
      ),
    }));

  return (
    <Wrapper>
      <Box sx={{ height: "85.5vh", position: "relative" }}>
        {isLoading ? (
          <Loading />
        ) : error ? (
          <ErrorPage error={error} />
        ) : vasTypes?.length ? (
          <StickyHeadTable
            refetch={refetch}
            data={vasTypes}
            dataLength={data?.totalCount}
            columns={columns}
          />
        ) : (
          <NoContentFound />
        )}
        <AddVas
          isOpen={isOpen}
          closeDialog={closeDialog}
          toggleToast={toggleToast}
          refetch={refetch}
          services={services as Array<ServiceProps>}
          iso2={iso2 as string}
          loading={loading}
        />

        <FabButton onClick={getServices} />

        {Boolean(toastMessage) && (
          <Toast
            open={Boolean(toastMessage)}
            message={toastMessage}
            onClose={() => toggleToast("")}
          />
        )}
      </Box>
    </Wrapper>
  );
};
export default VASPage;

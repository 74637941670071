import Menus from "Components/Shared/menu";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import MoreVert from "@mui/icons-material/MoreVert";
import { useMenu } from "Utils/hooks";
import { Link } from "react-router-dom";
import { KycMenuFunc } from "./type";
import ViewUserKyc from "./viewUserKyc";

const KycMenu: KycMenuFunc = (props) => {
  const { anchorEl, menuIsOpen, closeMenu, openMenu } = useMenu();
  return (
    <>
      <IconButton onClick={openMenu}>
        <MoreVert />
      </IconButton>
      <Menus anchorEl={anchorEl} open={menuIsOpen} closeMenu={closeMenu}>
        <div>
          <ViewUserKyc kycRequest={props.kycRequest} toggleToast={props.toggleToast} refetch={props.refetch}/>

          <Link to={`/user?userId=${props.userId}`}>
            <MenuItem disableRipple>View User Data</MenuItem>
          </Link>
        </div>
      </Menus>
    </>
  );
};
export default KycMenu;

import Stack from "@mui/material/Stack";
import InputField from "Components/Shared/textField";
import { useForm } from "Utils/hooks";
import ActionButton from "Components/Shared/button";
import { request } from "Utils";
import { useState } from "react";
import Progress from "Components/Shared/circleLoading";
import DialogComponent from "Components/Shared/dailog";
import { useMutation } from "react-query";
import { UpdateSchoolFunc } from "./type";
import { Box } from "@mui/material";

const UpdateSchool: UpdateSchoolFunc = ({
  toggleToast,
  isOpen,
  closeDialog,
  refetch,
  countryId,
  school
}) => {
  const { getData, values, submit } = useForm(submitForm);
  const [isLoading, setIsloading] = useState(false);

  const mutation = useMutation(
    async () => {
      setIsloading(true);
      return await request.patch({
        url: `/school-config/${countryId}/school/${school._id}`,
        data: values,
      });
    },
    {
      onSuccess: () => {
        refetch();
        setIsloading(false);
        closeDialog();
      },
      onError: ({ message }) => {
        toggleToast(message);
        setIsloading(false);
        closeDialog();
      },
    },
  );
  function submitForm() {
    mutation.mutate();
  }

  return (
    <div>
      <DialogComponent
        open={isOpen}
        onClose={closeDialog}
        title={`Update ${school.name}  Information`}
      >
        <form onSubmit={submit}>
          <Stack spacing={4} py={4}>
            <InputField
              type="text"
              label="School Name"
              name="name"
              defaultValue={school.name}
              onChange={getData}
              required
            />
            <InputField
              type="text"
              label="Bank Name"
              name="bankName"
              defaultValue={school.bankName}
              onChange={getData}
              required
            />
            <InputField
              type="text"
              label="Bank Address"
              name="bankAddress"
              defaultValue={school.bankAddress}
              onChange={getData}
              required
            />
            <Box sx={{ display: 'flex', gap: '10px' }}>
              <InputField
                fullWidth
                type="text"
                label="Account Name"
                name="accountName"
                defaultValue={school.accountName}
                onChange={getData}
                required
              />
              <InputField
                fullWidth
                type="text"
                label="Account Number"
                name="accountNo"
                defaultValue={school.accountNo}
                onChange={getData}
                required
              />
            </Box>
            <Box sx={{ display: 'flex', gap: '10px' }}>
              <InputField
                fullWidth
                type="text"
                label="IBAN"
                name="iban"
                defaultValue={school.iban}
                onChange={getData}
                required
              />
              <InputField
                fullWidth
                type="text"
                label="Swift Code"
                name="swiftCode"
                defaultValue={school.swiftCode}
                onChange={getData}
                required
              />
            </Box>
            <ActionButton type="submit" variant="contained">
              Update School Information{" "}
              {isLoading && (
                <Progress sx={{ color: "#fff", ml: 0.8 }} size={18} />
              )}
            </ActionButton>
          </Stack>
        </form>
      </DialogComponent>
    </div>
  );
};

export default UpdateSchool;

import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import { theme } from "Styles/theme";
import Image from "Components/Shared/image";
import TextFieldComponent from "Components/Shared/textField";
import BasicButtons from "Components/Shared/button";
import { useToast, useForm } from "Utils/hooks";
import Toast from "../../Components/Shared/toast";
import Progress from "../../Components/Shared/circleLoading";
import { useState } from "react";
import { Login } from "Services/User";
import { session } from "Utils";

export default function LoginPage() {
  const { getData, submit, values } = useForm(submitForm);
  const { toggleToast, toastMessage } = useToast();
  const [isLoading, setIsloading] = useState(false);

  async function submitForm() {
    try {
      setIsloading(true);
      const data = await Login(values);
      session.set("user", data);
      session.set("token", data.token);
      window.location.reload();
    } catch (error: any) {
      toggleToast(error.message);
    } finally {
      setIsloading(false);
    }
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          background: theme.palette.secondary.dark,
          height: "100vh",
        }}
      >
        <Container maxWidth="xs" sx={{}}>
          <Paper sx={{ textAlign: "center", px: 4, pb: 8 }}>
            <Image
              src="/images/logo.svg"
              alt="monirates logo"
              height={150}
              width={150}
            />
            <form onSubmit={submit}>
              <Stack spacing={4}>
                <TextFieldComponent
                  name="email"
                  onChange={getData}
                  required
                  variant="outlined"
                  label="Email"
                />
                <TextFieldComponent
                  name="password"
                  onChange={getData}
                  required
                  variant="outlined"
                  label="Password"
                  type="password"
                />
                <BasicButtons
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="large"
                  sx={{ py: 1.6, fontSize: 18 }}
                >
                  Sign in{" "}
                  {isLoading && (
                    <Progress sx={{ color: "#fff", ml: 0.8 }} size={18} />
                  )}
                </BasicButtons>
              </Stack>
            </form>
          </Paper>
        </Container>
      </Box>
      {Boolean(toastMessage) && (
        <Toast
          open={Boolean(toastMessage)}
          message={toastMessage}
          onClose={() => toggleToast("")}
        />
      )}
    </>
  );
}

import Stack from "@mui/material/Stack";
import DialogComponent from "Components/Shared/dailog";
import { Grid, Typography } from "@mui/material";
import DeleteConfiguration from "./deleteConfig";
import UpdateConfig from "./updateConfig";
import { ConfigurationsFunc } from "./type";

const Configurations: ConfigurationsFunc = ({
  toggleToast,
  isOpen,
  closeDialog,
  configurations,
  refetch,
  id,
}) => {
  return (
    <div>
      <DialogComponent
        open={isOpen}
        onClose={closeDialog}
        title="Configurations"
      >
        <Stack spacing={2} sx={{ width: "100%" }}>
          {configurations.map((configuration, index: number) => {
            return (
              <Grid spacing={2} container key={`configuration${index}`}>
                <Grid item md={5}>
                  <Typography variant="h5" fontWeight={700}>
                    {configuration?.configName?.replace(/_/g, " ")}:
                  </Typography>
                </Grid>
                <Grid item md={1.5}>
                  <Typography variant="h5" ml={2}>
                    {configuration.value}
                  </Typography>
                </Grid>
                <Grid item md={3}>
                  <Typography sx={{ textAlign: "end" }} variant="h5" ml={2}>
                    {configuration.type}
                  </Typography>
                </Grid>
                <Grid item md={1.5} sx={{ display: "flex" }}>
                  <UpdateConfig
                    refetch={refetch}
                    toggleToast={toggleToast}
                    configuration={configuration}
                    id={id}
                  />
                  <DeleteConfiguration
                    refetch={refetch}
                    toggleToast={toggleToast}
                    url={`/configuration/${id}/remove/${configuration.configTypeId}`}
                  />
                </Grid>
              </Grid>
            );
          })}
        </Stack>
      </DialogComponent>
    </div>
  );
};
export default Configurations;

import Box from "@mui/material/Box";
import Links from "./links";
import { SideNavFunc } from "./type";
import { useState } from "react";

const SideNav: SideNavFunc = () => {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <Box
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      sx={{
        position: "fixed",
        width: { md: "14%", lg: "12.5%" },
        overflowY: isHovered ? "scroll" : "hidden",
        maxHeight: "100vh",
        transition: "overflow 0.3s ease-in-out",
        scrollbarWidth: "thin", 
        WebkitOverflowScrolling: "touch", 
        "::-webkit-scrollbar": {
          width: "1px",
        },
        "::-webkit-scrollbar-thumb": {
          backgroundColor: "#eb2e67",
          borderRadius: "4px",
        },
      }}
    >
      <Box sx={{ position: "static" }}>
        <Links />
      </Box>
    </Box>
  );
};

export default SideNav;

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Image from "./image";

export default function NoContentFound({ message }: { message?: string }) {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "83vh",
      }}
    >
      <Box sx={{ textAlign: "center" }}>
        <Image
          src="/images/empty.svg"
          alt="monirates error"
          height="100%"
          width={350}
        />
        <Typography variant="h5" fontWeight={700} mt={2}>
          {message ? message : "No content Found!!!"}
        </Typography>
      </Box>
    </Box>
  );
}

import Box from "@mui/material/Box";
import ErrorPage from "Components/Shared/error";
import Loading from "Components/Shared/loading";
import StickyHeadTable from "Components/Shared/table";
import Wrapper from "Components/Wrapper";
import { useState } from "react";
import { useQuery } from "react-query";
import {  request, toLocaleDate } from "Utils";
import { UserInt } from "Utils/interface";
import NoContentFound from "Components/Shared/noContentFound";
import { useLocation } from "react-router-dom";
import { useToast } from "Utils/hooks";
import Toast from "Components/Shared/toast";
import NsanoMenu from "./menu";

const getTransactions = async () => {
  const { data } = await request.get({
    url: `/nsano/transactions`
  });
  return data;
};

const TransactionsPage = () => {
  const { toggleToast, toastMessage } = useToast();
  const [pageId, setPageId] = useState(1);
  const [limit, setLimit] = useState(10);

  const query = useLocation();

  const { data, error, isLoading, refetch } = useQuery({
    refetchOnMount: true,
    refetchOnWindowFocus: true,
    queryKey: ["transactions", { pageId, limit, filter: query.search }],
    queryFn: getTransactions,
    retry: 2
  });

  const columns = [
    { minWidth: 20, name: "No", key: "index" },
    { minWidth: 100, name: "Sender Account No", key: "senderAccountNo" },
    { minWidth: 100, name: "Sender Name", key: "senderName" },
    { minWidth: 50, name: "Recipient AccountNo", key: "recipientAccountNo" },
    { minWidth: 50, name: "Recipient Name", key: "recipientName" },
    { minWidth: 50, name: "Recipient Country", key: "recipientCountry" },
    { minWidth: 50, name: "Provider", key: "provider" },
    { minWidth: 50, name: "Reference", key: "reference" },
    { minWidth: 50, name: "Amount", key: "amount" },
    { minWidth: 50, name: "Narration", key: "narration" },
    { minWidth: 100, name: "Source Currency", key: "sourceCurrency" },
    { minWidth: 100, name: "Status", key: "status" },
    { minWidth: 150, name: "Created At", key: "createdAt" }
  ];

  const transactions = data?.map((transaction: UserInt, index: number) => ({
    index: ++index + limit * (pageId - 1),
    ...transaction,
    createdAt: toLocaleDate(transaction?.createdAt)
  }));

  if (isLoading) return <Loading />;
  if (error) return <ErrorPage error={error} />;

  return (
    <Wrapper>
      <div>
      <Box textAlign="right" mb={2}>
              <NsanoMenu refetch={refetch}
              />
            </Box>
        {data?.length ? (
          <Box pb={5}>
           
            <StickyHeadTable
              refetch={refetch}
              data={transactions}
              dataLength={data?.totalCount}
              columns={columns}
              setLimit={setLimit}
              limit={limit}
              setPageId={setPageId}
              pageId={pageId}
              pageCount={data.pageCount}
            />
          </Box>
        ) : (
          <NoContentFound />
        )}
        {Boolean(toastMessage) && (
          <Toast
            open={Boolean(toastMessage)}
            message={toastMessage}
            onClose={() => toggleToast("")}
          />
        )}
      </div>
    </Wrapper>
  );
};

export default TransactionsPage;

import Stack from "@mui/material/Stack";
import InputField from "Components/Shared/textField";
import { useForm } from "Utils/hooks";
import ActionButton from "Components/Shared/button";
import { request } from "Utils";
import { useState } from "react";
import Progress from "Components/Shared/circleLoading";
import DialogComponent from "Components/Shared/dailog";
import { useMutation } from "react-query";
import { UpdateCountryFunc } from "./type";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Box, Typography } from "@mui/material";
import { getAllISOCodes } from "iso-country-currency";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";


const UpdateExchangeRate: UpdateCountryFunc = ({
    toggleToast,
    isOpen,
    closeDialog,
    refetch,
    countryInfo: country
}) => {
    const { getData, values, submit } = useForm(submitForm);
    const [isLoading, setIsloading] = useState(false);
    const countryInformation = getAllISOCodes()

    const mutation = useMutation(
        async () => {
            const { exchangeCurrency, rate } = values;
            const exchangeRateObject = { [exchangeCurrency]: rate };
            const updatedValues = { exchangeRate: exchangeRateObject };
            setIsloading(true);
            return await request.patch({
                url: `/school-config/${country.id}/currency`,
                data: updatedValues,
            });
        },
        {
            onSuccess: () => {
                refetch();
                setIsloading(false);
                // closeDialog();
            },
            onError: ({ message }) => {
                toggleToast(message);
                setIsloading(false);
                closeDialog();
            },
        },
    );
    function submitForm() {
        mutation.mutate();
    }

    return (
        <div>
            <DialogComponent
                open={isOpen}
                onClose={closeDialog}
                title={`Update ${country.country}  Exchange rate`}
            >
                <Box sx={{ display: "flex", gap: "8px", flexWrap: "wrap" }}>
                    {country.exchangeRate && Object.entries(country.exchangeRate).map(([currency, rate], index) => (
                        <Box key={index} sx={{
                            display: "flex",
                            alignItems: "center",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                            padding: "4px 8px",
                            marginBottom: "8px",
                        }}>
                            <Typography>{currency}</Typography>
                            <ArrowForwardIcon />
                            <Typography>{rate}</Typography>
                        </Box>
                    ))}
                </Box>
                <form onSubmit={submit}>
                    <Stack spacing={4} py={4}>
                        <FormControl sx={{ width: { xs: "100%", md: "100%" }, mb: 3 }}>
                            <InputLabel>Exchange Currency</InputLabel>
                            <Select
                                name="exchangeCurrency"
                                labelId="exchangeCurrency"
                                label="Exchange Currency"
                                onChange={(e: any) => getData(e)}
                            >
                                {countryInformation
                                    ?.filter((value, index, self) =>
                                        self.findIndex((item) => item.currency === value.currency) === index // Filter out duplicates
                                    )
                                    .sort((a, b) => a.currency.localeCompare(b.currency)) // Sort by currency alphabetically
                                    .map((country, index) => (
                                        <MenuItem key={`${index}-country`} value={country.currency}>
                                            {country.currency}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                        <InputField
                            type="number"
                            label="Rate"
                            name="rate"
                            onChange={getData}
                        />

                        <ActionButton type="submit" variant="contained">
                            Update Exchange Rate{" "}
                            {isLoading && (
                                <Progress sx={{ color: "#fff", ml: 0.8 }} size={18} />
                            )}
                        </ActionButton>
                    </Stack>
                </form>
            </DialogComponent>
        </div >
    );
};

export default UpdateExchangeRate;

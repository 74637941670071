import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";

import ErrorPage from "Components/Shared/error";
import Loading from "Components/Shared/loading";
import Wrapper from "Components/Wrapper";
import { useState } from "react";
import { useQuery } from "react-query";
import { request } from "Utils";
import { useToast } from "Utils/hooks";
import Toast from "Components/Shared/toast";
import NoContentFound from "Components/Shared/noContentFound";
import { useNavigate, useSearchParams } from "react-router-dom";
import StickyHeadTable from "Components/Shared/table";
import { OfferStatsInt, OffersStatsPageFunc } from "./type";

const getOffers = async ({ queryKey }: any) => {
  const [, status] = queryKey;
  const { data } = await request.get({ url: `/offers/stats?status=${status}` });
  return data;
};

const OffersStatsPage: OffersStatsPageFunc = () => {
  const { toggleToast, toastMessage } = useToast();
  const [pageId, setPageId] = useState(1);
  const [limit, setLimit] = useState(10);
  const [getQuery] = useSearchParams();
  const navigate = useNavigate();
  let status = getQuery.get("status");
  const { data, error, isLoading, refetch } = useQuery({
    queryKey: ["Users", status],
    queryFn: getOffers,
    retry: 2,
    // staleTime: 86400000,
  });

  const columns = [
    { minWidth: 20, name: "No", key: "index" },
    { minWidth: 50, name: "Currency", key: "symbolic" },
    { minWidth: 50, name: "Total Available Amount", key: "totalAmount" },
    { minWidth: 50, name: "Total Offers", key: "totalOffers" },
  ];

  const offers = data?.map((offer: OfferStatsInt, index: number) => ({
    index: ++index,
    ...offer,
    currency: offer.currency,
    symbolic: (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Avatar
          src={offer.flag}
          alt={offer.currency}
          variant="square"
          sx={{ width: 30, height: 20, mr: 1 }}
        />{" "}
        <Typography fontWeight={700}>{offer.currency}</Typography>
      </Box>
    ),
    totalAmount: `${offer.currencySymbol} ${offer.totalAmount.toLocaleString(
      "en",
    )}`,
  }));

  const viewOffers = (value: Record<string, any>) => {
    navigate(`/offers?status=${status}&fromCurrency=${value.currency}`);
  };

  if (isLoading) return <Loading />;
  if (error) return <ErrorPage error={error} />;
  return (
    <Wrapper>
      <Container maxWidth="md">
        <Box sx={{ height: "85.5vh", position: "relative" }}>
          {data?.length ? (
            <Box pb={5}>
              <StickyHeadTable
                refetch={refetch}
                data={offers}
                dataLength={data?.totalCount}
                columns={columns}
                setLimit={setLimit}
                limit={limit}
                setPageId={setPageId}
                pageId={pageId}
                onClick={viewOffers}
                pageCount={data.pageCount}
              />
            </Box>
          ) : (
            <NoContentFound />
          )}

          {Boolean(toastMessage) && (
            <Toast
              open={Boolean(toastMessage)}
              message={toastMessage}
              onClose={() => toggleToast("")}
            />
          )}
        </Box>
      </Container>
    </Wrapper>
  );
};

export default OffersStatsPage;

import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import InputField from "Components/Shared/textField";
import { useDailog, useForm } from "Utils/hooks";
import ActionButton from "Components/Shared/button";
import { getQueryString, request } from "Utils";
import { OrderFilterFunc } from "./type";
import { useEffect, useState } from "react";
import { ConfigsInt } from "Components/Configuration/type";
import { useLocation, useNavigate } from "react-router-dom";

const OrderFilterAlign: OrderFilterFunc = ({ setFilter }) => {
  const { openDialog } = useDailog();
  const { getData, values, submit } = useForm(submitForm);
  const [countries, setCountries] = useState<Array<ConfigsInt>>();
  const query = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    let params;
    params = query.search?.split("?")[1];
    if (params) {
      params = params?.split("&");
      for (const item of params) {
        const key = item.split("=");
        values[key[0]] = key[1];
      }
    }
  }, [query.search, values]);

  useEffect(() => {
    listCountries();
  }, [setFilter]);
  const listCountries = async () => {
    try {
      const { data } = await request.get({ url: "/configurations" });
      setCountries([...data]);
      openDialog();
    } catch (error) {}
  };

  function submitForm() {
    navigate(`/orders${getQueryString(values)}`, { replace: true });
    // closeDialog();
  }

  const status = ["PENDING", "SUCCESSFUL", "CANCELLED", "FAILED", "PROCESSING"];
  const order = ["desc", "asc"];

  return (
    <div>
      {/* <MenuOutlinedIcon onClick={listCountries} sx={{fontSize: 40, mb: 3, cursor: "pointer"}} />
      <DialogComponent
        open={isOpen}
        onClose={closeDialog}
        title="Filter"
        maxWidth="sm"
      > */}
      <form onSubmit={submit}>
        <Stack spacing={3} py={3}>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between"
            }}
          >
            {/* <InputField
              type="number"
              label="Page number"
              name="pageId"
              onChange={getData}
              sx={{ width: { xs: "100%", md: "12%" }, mb: 3 }}
            />
            <InputField
              type="number"
              label="Limit"
              name="limit"
              onChange={getData}
              sx={{ width: { xs: "100%", md: "12%" }, mb: 3 }}
            /> */}
            {/* <InputField
              type="text"
              label="Offer Id"
              name="offerId"
              onChange={getData}
              value={values.offerId || ''}
              sx={{ width: { xs: "100%", md: "12%" }, mb: 3 }}
            /> */}
            <InputField
              type="text"
              label="Order Reference"
              name="globalId"
              onChange={getData}
              value={values.globalId || ""}
              sx={{ width: { xs: "100%", md: "12%" }, mb: 3 }}
            />
            <FormControl sx={{ width: { xs: "100%", md: "12%" }, mb: 3 }}>
              <InputLabel>Order Status</InputLabel>
              <Select
                name="status"
                labelId="status"
                label="Select status"
                // value={"PERCENTAGE" || "FIXED" || "TEXT"}
                onChange={(e: any) => getData(e)}
                value={values.status || ""}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {status?.map((status, index) => (
                  <MenuItem key={`${index}-status`} value={status}>
                    {status}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{ width: { xs: "100%", md: "12%" }, mb: 3 }}>
              <InputLabel>Order</InputLabel>
              <Select
                name="order"
                labelId="Order"
                label="Select order"
                // value={"PERCENTAGE" || "FIXED" || "TEXT"}
                onChange={(e: any) => getData(e)}
                value={values.order || ""}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {order?.map((order, index) => (
                  <MenuItem key={`${index}-order`} value={order}>
                    {order}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{ width: { xs: "100%", md: "12%" }, mb: 3 }}>
              <InputLabel>From Currency</InputLabel>
              <Select
                labelId="Currency"
                label="From Currency"
                name="fromCurrency"
                onChange={(e: any) => getData(e)}
                value={values.fromCurrency || ""}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {countries?.map((country, index) => (
                  <MenuItem key={`${index}-type`} value={country.currency}>
                    {country.country}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{ width: { xs: "100%", md: "12%" }, mb: 3 }}>
              <InputLabel>To Currency</InputLabel>
              <Select
                labelId="Currency"
                label="To Currency"
                name="toCurrency"
                onChange={(e: any) => getData(e)}
                value={values.toCurrency || ""}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {countries?.map((country, index) => (
                  <MenuItem key={`${index}-type`} value={country.currency}>
                    {country.country}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <ActionButton
              type="submit"
              variant="contained"
              sx={{ width: { xs: "100%", md: "12%" }, mb: 3 }}
            >
              Filter
            </ActionButton>
          </Box>
        </Stack>
      </form>
      {/* </DialogComponent> */}
    </div>
  );
};

export default OrderFilterAlign;

import Box from "@mui/material/Box";

import ErrorPage from "Components/Shared/error";
import Loading from "Components/Shared/loading";
import StickyHeadTable from "Components/Shared/table";
import Wrapper from "Components/Wrapper";
import { useState } from "react";
import { useQuery } from "react-query";
import { request } from "Utils";
import AddKYCtype from "./add";
import { useDailog, useToast } from "Utils/hooks";
import Toast from "Components/Shared/toast";
import FabButton from "Components/Shared/fab";
import KYCtableMenu from "./tableMenu";
import NoContentFound from "Components/Shared/noContentFound";
import { KYCInt, KYCTypesFunc } from "./type";

const getKyc = async ({ queryKey }: any) => {
  const [, pageId, limit] = queryKey;
  const { data } = await request.get({
    url: `/kyc-types?pageId=${pageId + 1}&limit=${limit}`,
  });
  return data;
};

const KYCtypes: KYCTypesFunc = () => {
  const { toggleToast, toastMessage } = useToast();
  const { isOpen, closeDialog, openDialog } = useDailog();
  const [pageId, setPageId] = useState(1);
  const [limit, setLimit] = useState(10);
  const { data, error, isLoading, refetch } = useQuery({
    queryKey: ["Users", pageId, limit],
    queryFn: getKyc,
    retry: 2,
    // staleTime: 86400000,
  });

  const columns = [
    { minWidth: 20, name: "No", key: "index" },
    { minWidth: 50, name: "Name", key: "name" },
    { minWidth: 50, name: "Type", key: "type" },
    { minWidth: 50, name: "Field", key: "field" },
    { minWidth: 50, name: "Verifier", key: "verifier" },
    { minWidth: 50, name: "Action", key: "action" },
  ];
  const kycTypes =
    data &&
    data?.length &&
    data?.map((kyc: KYCInt, index: number) => ({
      index: ++index + limit * (pageId - 1),
      ...kyc,
      action: (
        <KYCtableMenu kyc={kyc} refetch={refetch} toggleToast={toggleToast} />
      ),
    }));

  return (
    <Wrapper>
      <Box sx={{ height: "85.5vh", position: "relative" }}>
        {isLoading ? (
          <Loading />
        ) : error ? (
          <ErrorPage error={error} />
        ) : kycTypes?.length ? (
          <StickyHeadTable
            refetch={refetch}
            data={kycTypes}
            dataLength={data?.totalCount}
            columns={columns}
            setLimit={setLimit}
            limit={limit}
            setPageId={setPageId}
            pageId={pageId}
            pageCount={data.pageCount}
          />
        ) : (
          <NoContentFound />
        )}
        <AddKYCtype
          isOpen={isOpen}
          closeDialog={closeDialog}
          toggleToast={toggleToast}
          refetch={refetch}
        />

        <FabButton onClick={openDialog} />

        {Boolean(toastMessage) && (
          <Toast
            open={Boolean(toastMessage)}
            message={toastMessage}
            onClose={() => toggleToast("")}
          />
        )}
      </Box>
    </Wrapper>
  );
};
export default KYCtypes;

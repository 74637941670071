import React from 'react'
import { SchoolTypeMenuFunc } from './type'
import { useDailog, useMenu } from 'Utils/hooks';
import IconButton from '@mui/material/IconButton';
import MoreVert from '@mui/icons-material/MoreVert';
import Menus from 'Components/Shared/menu';
import { MenuItem } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import SchoolIcon from '@mui/icons-material/School';
import UpdateSchool from './updateSchool';
import ConfirmDelete from 'Components/Shared/confirmDelete';


const SchoolMenu: SchoolTypeMenuFunc = (props : any) => {
  const updateDialog = useDailog()
  const confirmDeleteModal = useDailog()
  const { anchorEl, menuIsOpen, closeMenu, openMenu } = useMenu();
  return (
    <>
      <IconButton onClick={openMenu}>
        <MoreVert />
      </IconButton>
      <Menus anchorEl={anchorEl} open={menuIsOpen} closeMenu={closeMenu}>
        <div>
          <MenuItem onClick={updateDialog.openDialog}>
            <SchoolIcon /> Update School Information
          </MenuItem>
          <MenuItem
            onClick={confirmDeleteModal.openDialog}
            sx={{ color: "red" }}
            disableRipple
          >
            <DeleteIcon />
            Delete School
          </MenuItem>
        </div>
      </Menus>

      <UpdateSchool
        refetch={props.refetch}
        isOpen={updateDialog.isOpen}
        closeDialog={updateDialog.closeDialog}
        toggleToast={props.toggleToast}
        countryId={props.countryId}
        school={props.school} />

      <ConfirmDelete
        refetch={props.refetch}
        isOpen={confirmDeleteModal.isOpen}
        closeDialog={confirmDeleteModal.closeDialog}
        toggleToast={props.toggleToast}
        url={`/school-config/${props.countryId}/school/${props.school._id}`}
      />
    </>
  )
}

export default SchoolMenu
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";

import ErrorPage from "Components/Shared/error";
import Loading from "Components/Shared/loading";
import Wrapper from "Components/Wrapper";
import { useState } from "react";
import { useQuery } from "react-query";
import { request } from "Utils";
import { useToast } from "Utils/hooks";
import Toast from "Components/Shared/toast";
import NoContentFound from "Components/Shared/noContentFound";
import StickyHeadTable from "Components/Shared/table";
import { useNavigate, useSearchParams } from "react-router-dom";
import { OrderStatisticsPageInt } from "./type";
import { OfferStatsInt } from "Components/Offers/type";

const getOrders = async ({ queryKey }: any) => {
  const [, status] = queryKey;
  const { data } = await request.get({ url: `/orders/stats?status=${status}` });
  return data;
};

const OrderStatisticsPage: OrderStatisticsPageInt = () => {
  const { toggleToast, toastMessage } = useToast();
  const [pageId, setPageId] = useState(1);
  const [limit, setLimit] = useState(10);
  const [getQuery] = useSearchParams();
  const navigate = useNavigate();
  const status = getQuery.get("status");
  const { data, error, isLoading, refetch } = useQuery({
    queryKey: ["Users", status],
    queryFn: getOrders,
    retry: 2,
    // staleTime: 86400000,
  });
  const columns = [
    { minWidth: 20, name: "No", key: "index" },
    { minWidth: 50, name: "Currency", key: "symbolic" },
    { minWidth: 50, name: "Total Amount", key: "totalAmount" },
    { minWidth: 50, name: "Total Orders", key: "totalOrders" },
  ];
  const ViewOrders = (value: Record<string, any>) => {
    navigate(`/orders?status=${status}&fromCurrency=${value.currency}`);
  };
  const orders =
    data && data?.length
      ? data?.map((order: OfferStatsInt, index: number) => ({
          index: ++index + limit * (pageId - 1),

          ...order,
          currency: order.currency,
          symbolic: (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Avatar
                src={order.flag}
                alt={order.currency}
                variant="square"
                sx={{ width: 30, height: 20, mr: 1 }}
              />
              <Typography fontWeight={700}>{order.currency}</Typography>
            </Box>
          ),
          totalAmount: `${
            order.currencySymbol
          } ${order.totalAmount.toLocaleString("en")}`,
        }))
      : "";
  return (
    <Wrapper>
      <Container maxWidth="md">
        <Box sx={{ height: "85.5vh", position: "relative" }}>
          {isLoading ? (
            <Loading />
          ) : error ? (
            <ErrorPage error={error} />
          ) : orders?.length ? (
            <Box pb={5}>
              <StickyHeadTable
                refetch={refetch}
                data={orders}
                dataLength={data?.totalCount}
                columns={columns}
                setLimit={setLimit}
                limit={limit}
                setPageId={setPageId}
                pageId={pageId}
                pageCount={data.pageCount}
                onClick={ViewOrders}
              />
            </Box>
          ) : (
            <NoContentFound />
          )}

          {Boolean(toastMessage) && (
            <Toast
              open={Boolean(toastMessage)}
              message={toastMessage}
              onClose={() => toggleToast("")}
            />
          )}
        </Box>
      </Container>
    </Wrapper>
  );
};
export default OrderStatisticsPage;

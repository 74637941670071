import Box from "@mui/material/Box";

import ErrorPage from "Components/Shared/error";
import Loading from "Components/Shared/loading";
import Wrapper from "Components/Wrapper";
import { useState } from "react";
import { useQuery } from "react-query";
import { getQueryString, request } from "Utils";
import { useToast } from "Utils/hooks";
import Toast from "Components/Shared/toast";
import NoContentFound from "Components/Shared/noContentFound";
import { useSearchParams } from "react-router-dom";
import StickyHeadTable from "Components/Shared/table";
// import OfferFilter from "./filter";
import OfferMenu from "./menu";
import { OfferInt, OffersPageFunc } from "./type";
import OfferFilterAlign from "./filter_align";

const getOffers = async ({ queryKey }: any) => {
  const [
    ,
    pageId,
    limit,
    userGlobalId,
    status,
    currency,
    globalId,
    offerGlobalId,
    filter,
  ] = queryKey;
  const query = {
    pageId,
    limit,
    userGlobalId,
    status,
    globalId,
    offerGlobalId,
    toCurrency: currency,
  };
  const queryParams = filter ? filter : getQueryString(query);
  const { data } = await request.get({ url: `/offers${queryParams}` });
  return data;
};

const OffersStatistics: OffersPageFunc = () => {
  const { toggleToast, toastMessage } = useToast();
  const [filter, setFilter] = useState("");
  const [pageId, setPageId] = useState(1);
  const [limit, setLimit] = useState(10);
  const [getQuery] = useSearchParams();
  const userGlobalId = getQuery.get("userGlobalId");
  const status = getQuery.get("status");
  const currency = getQuery.get("currency");
  const globalId = getQuery.get("globalId");
  const offerGlobalId = getQuery.get("offerGlobalId");

  const { data, error, isLoading, refetch } = useQuery({
    queryKey: [
      "Users",
      pageId,
      limit,
      userGlobalId,
      status,
      currency,
      globalId,
      offerGlobalId,
      filter,
    ],
    queryFn: getOffers,
    retry: 2,
    // staleTime: 86400000,
  });
  const columns = [
    { minWidth: 20, name: "No", key: "index" },
    { minWidth: 50, name: "Available", key: "available" },
    { minWidth: 50, name: "Initial Amount", key: "initialAmount" },
    { minWidth: 50, name: "Exchange Rate", key: "displayRate" },
    { minWidth: 50, name: "From Currency", key: "fromCurrency" },
    { minWidth: 50, name: "To Currency", key: "toCurrency" },
    { minWidth: 50, name: "Status", key: "status" },
    { minWidth: 50, name: "Action", key: "action" },
  ];

  const offers = data?.offers?.map((offer: OfferInt, index: number) => ({
    index: ++index,
    ...offer,
    available: `${offer.currencySymbol}${offer.available.toLocaleString("en")}`,
    initialAmount: `${offer.currencySymbol}${offer.initialAmount.toLocaleString(
      "en",
    )}`,
    displayRate: `${offer.currencySymbol}${offer.displayRate.toLocaleString(
      "en",
    )}`,
    fromCurrency: offer?.fromCurrency?.name,
    toCurrency: offer?.toCurrency?.name,
    action: <OfferMenu offerGlobalId={offer._id} globalId={offer.globalId} />,
  }));
  if (isLoading) return <Loading />;
  if (error) return <ErrorPage error={error} />;
  return (
    <Wrapper>
      <Box sx={{ height: "85.5vh", position: "relative" }}>
        {data?.offers?.length ? (
          <Box pb={5}>
            <OfferFilterAlign setFilter={setFilter} />
            {/* <Box textAlign="right">
              <OfferFilter setFilter={setFilter}/>
            </Box> */}
            <StickyHeadTable
              refetch={refetch}
              data={offers}
              dataLength={data?.totalCount}
              columns={columns}
              setLimit={setLimit}
              limit={limit}
              setPageId={setPageId}
              pageId={pageId}
              pageCount={data.pageCount}
            />
          </Box>
        ) : (
          <NoContentFound />
        )}

        {Boolean(toastMessage) && (
          <Toast
            open={Boolean(toastMessage)}
            message={toastMessage}
            onClose={() => toggleToast("")}
          />
        )}
      </Box>
    </Wrapper>
  );
};
export default OffersStatistics;

import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";

import ErrorPage from "Components/Shared/error";
import Loading from "Components/Shared/loading";
import StickyHeadTable from "Components/Shared/table";
import Wrapper from "Components/Wrapper";
import { useState } from "react";
import { useQuery } from "react-query";
import { request } from "Utils";
import { useToast } from "Utils/hooks";
import Toast from "Components/Shared/toast";
import { useSearchParams } from "react-router-dom";
import { WalletInt, WalletsPageFunc } from "./type";
import WalletMenu from "./walletMenu";
import NoContentFound from "Components/Shared/noContentFound";

const getWallets = async ({ queryKey }: any) => {
  const [, globalId] = queryKey;
  const { data } = await request.get({
    url: `/wallet/user/balance?key=${globalId}`,
  });
  return data;
};

const WalletsPage: WalletsPageFunc = () => {
  const { toggleToast, toastMessage } = useToast();
  const [pageId, setPageId] = useState(1);
  const [limit, setLimit] = useState(10);
  const [getQuery] = useSearchParams();
  let globalId = getQuery.get("globalId");
  const { data, error, isLoading, refetch } = useQuery({
    queryKey: ["Users", globalId],
    queryFn: getWallets,
  });

  const columns = [
    { minWidth: 20, name: "No", key: "index" },
    { minWidth: 50, name: "Currency", key: "currency" },
    { minWidth: 50, name: "Balance", key: "balance" },
    { minWidth: 50, name: "Incoming Balance", key: "incomingBalance" },
    { minWidth: 50, name: "Held Balance", key: "heldBalance" },
    { minWidth: 50, name: "Status", key: "status" },
    { minWidth: 50, name: "Action", key: "action" },
  ];

  const kycTypes =
    data?.pockets?.length &&
    data?.pockets?.map((wallet: WalletInt, index: number) => ({
      index: ++index + limit * (pageId - 1),

      ...wallet,
      currency: (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Avatar
            src={wallet.flag}
            alt={wallet.currency}
            variant="square"
            sx={{ width: 30, height: 20, mr: 1 }}
          />
          <Typography fontWeight={700}>{wallet.currency}</Typography>
        </Box>
      ),
      balance: `${wallet.symbol} ${wallet.balance}`,
      heldBalance: `${wallet.symbol} ${wallet.heldBalance}`,
      incomingBalance: `${wallet.symbol} ${wallet.incomingBalance}`,
      action: (
        <WalletMenu
          bankDetails={wallet.bankDetails}
          defaultStatus={wallet.status}
          toggleToast={toggleToast}
          refetch={refetch}
          walletId={data.id}
          pocketId={wallet.id}
        />
      ),
    }));

  if (isLoading) return <Loading />;
  if (error) return <ErrorPage error={error} />;
  return (
    <Wrapper>
      <Box sx={{ height: "85.5vh", position: "relative" }}>
        {data?.pockets?.length ? (
          <>
            <StickyHeadTable
              refetch={refetch}
              data={kycTypes}
              dataLength={data?.totalCount}
              columns={columns}
              setLimit={setLimit}
              limit={limit}
              setPageId={setPageId}
              pageId={pageId}
              pageCount={data.pageCount}
            />
          </>
        ) : (
          <NoContentFound />
        )}

        {Boolean(toastMessage) && (
          <Toast
            open={Boolean(toastMessage)}
            message={toastMessage}
            onClose={() => toggleToast("")}
          />
        )}
      </Box>
    </Wrapper>
  );
};
export default WalletsPage;


import DialogComponent from "Components/Shared/dailog";
import { SchoolInfoFunc } from "./type";
import { Box, Typography } from "@mui/material";

const SchoolInformation: SchoolInfoFunc = ({
    toggleToast,
    isOpen,
    closeDialog,
    refetch,
    school
}) => {

    return (
        <div>
            <DialogComponent
                open={isOpen}
                onClose={closeDialog}
                title={`School Information`}
            >
                <Box >
                    <Typography sx={{fontSize : '18px', fontWeight : 'bold'}}>School Name : {school.name}</Typography>
                    <Typography sx={{fontSize : '18px' , fontWeight : 'bold'}}>Account Number : {school.accountNo}</Typography>
                    <Typography sx={{fontSize : '18px' , fontWeight : 'bold'}}>Bank Name : {school.bankName}</Typography>
                    <Typography sx={{fontSize : '18px' , fontWeight : 'bold'}}>Bank Address : {school.bankAddress}</Typography>
                    <Typography sx={{fontSize : '18px' , fontWeight : 'bold'}}>Account Name : {school.accountName}</Typography>
                    <Typography sx={{fontSize : '18px' , fontWeight : 'bold'}}>Iban : {school.iban}</Typography>
                    <Typography sx={{fontSize : '18px' , fontWeight : 'bold'}}>Swift Code : {school.swiftCode}</Typography>

                </Box>
            </DialogComponent>
        </div>
    );
};

export default SchoolInformation;

import Menus from "Components/Shared/menu";
import IconButton from "@mui/material/IconButton";
import MoreVert from "@mui/icons-material/MoreVert";
import { useMenu } from "Utils/hooks";
import BankDetails from "./bankAcountDetails";
import { WalletMenuFunc } from "./type";
import ChangeWalletStatus from "./changeWalletStatus";
import { Link } from "react-router-dom";
import { MenuItem } from "@mui/material";

const WalletMenu: WalletMenuFunc = (props) => {
  const { anchorEl, menuIsOpen, closeMenu, openMenu } = useMenu();
  // function redirectLocation() {
  //   localStorage.getItem('userId');
  // }
  return (
    <>
      <IconButton onClick={openMenu}>
        <MoreVert />
      </IconButton>
      <Menus anchorEl={anchorEl} open={menuIsOpen} closeMenu={closeMenu}>
        <div>
          {props.bankDetails && <BankDetails bankDetails={props.bankDetails} />}
          <ChangeWalletStatus
            defaultStatus={props.defaultStatus}
            toggleToast={props.toggleToast}
            refetch={props.refetch}
            walletId={props.walletId}
            pocketId={props.pocketId}
          />
          <Link to={`/transactions/?walletId=${props.walletId}`}>
            <MenuItem disableRipple>View Wallet Transactions</MenuItem>
          </Link>
        </div>
      </Menus>
    </>
  );
};

export default WalletMenu;

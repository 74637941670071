import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Chart, ReactGoogleChartEvent } from "react-google-charts";
import { useNavigate } from "react-router-dom";
// import { UserStatsFunc } from "./types";

const UserStats = ({ totalUsers }: any) => {
  const navigate = useNavigate();
  const keysWithValue = [] as Array<string>;
  const labels = [] as Array<Array<any>>;

  Object.keys(totalUsers).forEach((key) => {
    if (totalUsers[key] > 0 && key !== "totalCount") {
      // if (totalUsers[key] > 0) {
      keysWithValue.push(key);
      labels.push([key, totalUsers[key]]);
    }
  });
  const data = [keysWithValue, ...labels];
  const readyEvent: ReactGoogleChartEvent[] = [
    {
      eventName: "select",
      callback: async ({ chartWrapper }: any) => {
        const value = chartWrapper.getChart().getSelection();
        const status = labels[value[0].row][0];
        navigate(`/users?status=${status.split("U")[0].toUpperCase()}`);
      },
    },
  ];

  const options = {
    title: "Users Verification Statistic",
    is3D: true,
  };

  return (
    <Card sx={{ boxShadow: "0px 1px 70px 1px #f1f1f1", mt: 5 }}>
      <CardContent sx={{ textAlign: "center" }}>
        <Chart
          chartType="PieChart"
          data={data}
          options={options}
          width={"100%"}
          height={"400px"}
          chartEvents={readyEvent}
        />
      </CardContent>
    </Card>
  );
};
export default UserStats;

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

import ErrorPage from "Components/Shared/error";
import Loading from "Components/Shared/loading";
import Wrapper from "Components/Wrapper";
import { useQuery } from "react-query";
import { request } from "Utils";
import { useParams } from "react-router-dom";
import NoContentFound from "Components/Shared/noContentFound";
import { KYCUserFunc, KYCint } from "./type";
import KYCCard from "Components/Shared/cards/kyc";

const getUsers = async ({ queryKey }: any) => {
  const [, pageId, limit, userId] = queryKey;
  const { data } = await request.get({
    url: `/kyc/user/${userId}?pageId=${pageId + 1}&limit=${limit}`,
  });
  return data;
};

const UserKYC: KYCUserFunc = () => {
  const { userId } = useParams();
  const { data, error, isLoading } = useQuery({
    queryKey: ["Users", 1, 40, userId],
    queryFn: getUsers,
  });
  return (
    <Wrapper>
      <div>
        {isLoading ? (
          <Loading />
        ) : error ? (
          <ErrorPage error={error} />
        ) : data.length ? (
          <>
            <Box pb={5} sx={{ height: data.length <= 15 ? "77vh" : "" }}>
              <Grid container spacing={2}>
                {data?.map((kyc: KYCint, index: number) => (
                  <Grid item md={3}>
                    <KYCCard {...kyc} key={index} />
                  </Grid>
                ))}
              </Grid>
            </Box>
          </>
        ) : (
          <NoContentFound />
        )}
      </div>
    </Wrapper>
  );
};

export default UserKYC;

import DialogComponent from "Components/Shared/dailog";
import { OrderDetailsFunc } from "./type";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const OrderDetails: OrderDetailsFunc = ({ isOpen, closeDialog, order }) => {
  return (
    <div>
      <DialogComponent
        open={isOpen}
        onClose={closeDialog}
        title={`Bank Details`}
      >
        <Box>
          <Box>
            <Typography
              sx={{ textAlign: "center", fontSize: "20px", fontWeight: "bold" }}
            >
              Buyers Credit Payment Details
            </Typography>
            <Typography sx={{ marginBottom: "3px", fontWeight: "semi-bold" }}>
              Payment Method : {order?.buyerCreditPaymentDetails?.paymentMethod}
            </Typography>
            <Typography sx={{ marginBottom: "3px", fontWeight: "semi-bold" }}>
              Account Name : {order?.buyerCreditPaymentDetails?.accountName}
            </Typography>
            <Typography sx={{ marginBottom: "3px", fontWeight: "semi-bold" }}>
              Account Number : {order?.buyerCreditPaymentDetails?.accountNumber}
            </Typography>
            <Typography sx={{ marginBottom: "3px", fontWeight: "semi-bold" }}>
              Provider's Name : {order?.buyerCreditPaymentDetails?.providerName}
            </Typography>
            <Typography sx={{ marginBottom: "3px", fontWeight: "semi-bold" }}>
              Bank Code : {order?.buyerCreditPaymentDetails?.bankCode}
            </Typography>
            <Typography sx={{ marginBottom: "3px", fontWeight: "semi-bold" }}>
              Network : {order?.buyerCreditPaymentDetails?.network}
            </Typography>
            <Typography sx={{ marginBottom: "3px", fontWeight: "semi-bold" }}>
              Currency : {order?.buyerCreditPaymentDetails?.currency}
            </Typography>
          </Box>
          <Box>
            <Typography
              sx={{ textAlign: "center", fontSize: "20px", fontWeight: "bold" }}
            >
              Buyers Debit Payment Details
            </Typography>
            <Typography sx={{ marginBottom: "3px", fontWeight: "semi-bold" }}>
              Payment Method : {order?.buyerDebitPaymentDetails?.paymentMethod}
            </Typography>
            <Typography sx={{ marginBottom: "3px", fontWeight: "semi-bold" }}>
              Account Name : {order?.buyerDebitPaymentDetails?.accountName}
            </Typography>
            <Typography sx={{ marginBottom: "3px", fontWeight: "semi-bold" }}>
              Account Number : {order?.buyerDebitPaymentDetails?.accountNumber}
            </Typography>
            <Typography sx={{ marginBottom: "3px", fontWeight: "semi-bold" }}>
              Provider's Name : {order?.buyerDebitPaymentDetails?.providerName}
            </Typography>
            <Typography sx={{ marginBottom: "3px", fontWeight: "semi-bold" }}>
              Bank Code : {order?.buyerDebitPaymentDetails?.bankCode}
            </Typography>
            <Typography sx={{ marginBottom: "3px", fontWeight: "semi-bold" }}>
              Network : {order?.buyerDebitPaymentDetails?.network}
            </Typography>
            <Typography sx={{ marginBottom: "3px", fontWeight: "semi-bold" }}>
              Currency : {order?.buyerDebitPaymentDetails?.currency}
            </Typography>
          </Box>
          <Box>
            <Typography
              sx={{ textAlign: "center", fontSize: "20px", fontWeight: "bold" }}
            >
              Sellers Credit Payment Details
            </Typography>
            <Typography sx={{ marginBottom: "3px", fontWeight: "semi-bold" }}>
              Payment Method :{" "}
              {order?.sellerCreditPaymentDetails?.paymentMethod}
            </Typography>
            <Typography sx={{ marginBottom: "3px", fontWeight: "semi-bold" }}>
              Account Name : {order?.sellerCreditPaymentDetails?.accountName}
            </Typography>
            <Typography sx={{ marginBottom: "3px", fontWeight: "semi-bold" }}>
              Account Number :{" "}
              {order?.sellerCreditPaymentDetails?.accountNumber}
            </Typography>
            <Typography sx={{ marginBottom: "3px", fontWeight: "semi-bold" }}>
              Provider's Name :{" "}
              {order?.sellerCreditPaymentDetails?.providerName}
            </Typography>
            <Typography sx={{ marginBottom: "3px", fontWeight: "semi-bold" }}>
              Bank Code : {order?.sellerCreditPaymentDetails?.bankCode}
            </Typography>
            <Typography sx={{ marginBottom: "3px", fontWeight: "semi-bold" }}>
              Network : {order?.sellerCreditPaymentDetails?.network}
            </Typography>
            <Typography sx={{ marginBottom: "3px", fontWeight: "semi-bold" }}>
              Currency : {order?.sellerCreditPaymentDetails?.currency}
            </Typography>
          </Box>
        </Box>
      </DialogComponent>
    </div>
  );
};

export default OrderDetails;

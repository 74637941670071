import Box from "@mui/material/Box";

import ErrorPage from "Components/Shared/error";
import Loading from "Components/Shared/loading";
import StickyHeadTable from "Components/Shared/table";
import Wrapper from "Components/Wrapper";
import { useState } from "react";
import { useQuery } from "react-query";
import { getQueryString, request, toLocaleDate } from "Utils";
import { useToast } from "Utils/hooks";
import Toast from "Components/Shared/toast";
import TransactionMenu from "./menu";
import { TransactionInt, TransactionsFunc } from "./type";
import { useSearchParams } from "react-router-dom";
import NoContentFound from "Components/Shared/noContentFound";
import TransactionFilter from "./filter_align";

const getWallets = async ({ queryKey }: any) => {
  const [
    ,
    pageId,
    limit,
    userGlobalId,
    currency,
    walletId,
    orderGlobalId,
    filter,
    status,
  ] = queryKey;
  const query = {
    pageId,
    limit,
    userGlobalId,
    currency,
    walletId,
    orderGlobalId,
    status,
  };
  const queryParams = filter ? filter : getQueryString(query);
  const { data } = await request.get({ url: `/transactions${queryParams}` });
  return data;
};

const FilterTransactions: TransactionsFunc = () => {
  const { toggleToast, toastMessage } = useToast();
  const [pageId, setPageId] = useState(1);
  const [filter, setFilter] = useState("");
  const [limit, setLimit] = useState(10);
  const [getQuery] = useSearchParams();
  const status = getQuery.get("status");
  const userGlobalId = getQuery.get("userGlobalId");
  const currency = getQuery.get("currency");
  const walletId = getQuery.get("walletId");
  const orderGlobalId = getQuery.get("orderGlobalId");
  const { data, error, isLoading, refetch } = useQuery({
    queryKey: [
      "Users",
      pageId,
      limit,
      userGlobalId,
      currency,
      walletId,
      orderGlobalId,
      filter,
      status,
    ],
    queryFn: getWallets,
    retry: 2,
    // staleTime: 86400000,
  });
  const columns = [
    { minWidth: 20, name: "No", key: "index" },
    { minWidth: 50, name: "Amount", key: "amount" },
    { minWidth: 50, name: "Currency", key: "currency" },
    { minWidth: 50, name: "Fee", key: "fee" },
    { minWidth: 50, name: "Payment Gateway", key: "paymentGateway" },
    { minWidth: 50, name: "Payment Method", key: "paymentMethod" },
    { minWidth: 50, name: "Purpose", key: "purpose" },
    { minWidth: 50, name: "Status", key: "status" },
    { minWidth: 20, name: "Created At", key: "createdAt" },
    { minWidth: 50, name: "Type", key: "type" },
    { minWidth: 50, name: "Action", key: "action" },
  ];
  const transactions = data?.transactions?.map(
    (transaction: TransactionInt, index: number) => ({
      index: ++index + limit * (pageId - 1),
      ...transaction,
      createdAt: toLocaleDate(transaction?.createdAt),

      amount: `${
        transaction.currencySymbol
      } ${transaction.amount.toLocaleString("en")}`,
      fee: `${transaction.currencySymbol} ${transaction.fee.toLocaleString(
        "en",
      )}`,
      action: (
        <TransactionMenu
          transactionId={transaction.id}
          walletId={transaction.walletId}
          toggleToast={toggleToast}
        />
      ),
    }),
  );
  if (isLoading) return <Loading />;
  if (error) return <ErrorPage error={error} />;
  return (
    <Wrapper>
      {data?.transactions?.length ? (
        <Box sx={{ height: "85.5vh", position: "relative" }}>
          <Box textAlign="right">
            <TransactionFilter setFilter={setFilter} />
          </Box>
          <StickyHeadTable
            refetch={refetch}
            data={transactions}
            dataLength={data?.totalTransactions}
            columns={columns}
            setLimit={setLimit}
            limit={limit}
            setPageId={setPageId}
            pageId={pageId}
            pageCount={data.pageCount}
          />
          {Boolean(toastMessage) && (
            <Toast
              open={Boolean(toastMessage)}
              message={toastMessage}
              onClose={() => toggleToast("")}
            />
          )}
        </Box>
      ) : (
        <NoContentFound />
      )}
    </Wrapper>
  );
};

export default FilterTransactions;

import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import PendingOutlinedIcon from "@mui/icons-material/PendingOutlined";
import UnpublishedOutlinedIcon from "@mui/icons-material/UnpublishedOutlined";
import ShoppingCartOutlined from "@mui/icons-material/ShoppingCartOutlined";
import AdminPanelSettingsOutlined from "@mui/icons-material/AdminPanelSettingsOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import PeopleOutlineOutlinedIcon from "@mui/icons-material/PeopleOutlineOutlined";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

import Wrapper from "Components/Wrapper";
import Card from "./card";
import BarChart from "./OfferStatsIntBarChart";
import DoughnutChart from "./userStatsDoughnut";
import { useQuery } from "react-query";
import { request } from "Utils";
import Loading from "Components/Shared/loading";
import ErrorPage from "Components/Shared/error";
import RatigBarChart from "./rating";
import PieChart from "./pieChart";
import { DashboardFunc, TotalUsersInt } from "./types";
import { useEffect, useState } from "react";
import { Money } from "@mui/icons-material";
import { UsersDetails } from "Utils/interface";

const getConfigurations = async () => {
  const { data } = await request.get({ url: "/dashboard" });
  return data;
};

const DashboardPage: DashboardFunc = () => {
  const [userDetails, setUserDetails] = useState<UsersDetails | null>(null);
  useEffect(() => {
    const userDetailsString = localStorage.getItem("user");
    if (userDetailsString) {
      const userDetailsObject = JSON.parse(userDetailsString);
      setUserDetails(userDetailsObject);
    }
  }, []);

  const { data, error, isLoading } = useQuery({
    queryKey: ["dashboard"],
    queryFn: getConfigurations,
    refetchInterval: 60000,
    retry: 2,
  });
  const [transactions, setTransactions] = useState({
    openedTransactions: 0,
    closedTransactions: 0,
    unknownTransactions: 0,
  });
  useEffect(() => {
    let transactionsSummary = {
      openedTransactions: 0,
      closedTransactions: 0,
      unknownTransactions: 0,
    };

    let transactions = data?.transactionStats;
    Array.isArray(transactions) &&
      transactions.forEach((transaction) => {
        transactionsSummary.unknownTransactions +=
          transaction.unknownTransactions;
        transactionsSummary.openedTransactions +=
          transaction.pendingTransactions;
        transactionsSummary.closedTransactions +=
          transaction.failedTransactions;
      });

    setTransactions({
      openedTransactions: transactionsSummary.openedTransactions,
      closedTransactions: transactionsSummary.closedTransactions,
      unknownTransactions: transactionsSummary.unknownTransactions,
    });
  }, [data]);

  const iconSx = { mr: 1, fontSize: 20 };

  const details = [
    {
      title: "Open Offers",
      count: data?.totalOffers?.openOffers,
      icon: <AdminPanelSettingsOutlined color="primary" sx={iconSx} />,
      link: "/offers/stats?status=OPEN",
    },
    {
      title: "Fulfilled Offers",
      count: data?.totalOffers?.fulfilledOffers,
      icon: <CheckCircleOutlineOutlinedIcon color="primary" sx={iconSx} />,
      link: "/offers/stats?status=SOLD",
    },
    {
      title: "Cancelled Offers",
      count: data?.totalOffers?.canceledOffers,
      icon: <CancelOutlinedIcon color="primary" sx={iconSx} />,
      link: "/offers/stats?status=CANCELLED",
    },
    {
      title: "Pending Orders",
      count: data?.totalOrders?.pendingOrders,
      icon: <PendingOutlinedIcon color="primary" sx={iconSx} />,
      link: "/orders/stats?status=PENDING",
    },
    {
      title: "Fulfilled Orders",
      count: data?.totalOrders?.fulfilledOrders,
      icon: <ShoppingCartOutlined color="primary" sx={iconSx} />,
      link: "/orders/stats?status=SUCCESSFUL",
    },
    {
      title: "Cancelled Orders",
      count: data?.totalOrders?.canceledOrders,
      icon: <CancelOutlinedIcon color="primary" sx={iconSx} />,
      link: "/orders/stats?status=CANCELLED",
    },
    {
      title: "Failed Orders",
      count: data?.totalOrders?.failedOrders,
      icon: <UnpublishedOutlinedIcon color="primary" sx={iconSx} />,
      link: "/orders/stats?status=FAILED",
    },
    {
      title: "Total Users",
      count: data?.totalUsers?.totalCount,
      icon: <PeopleOutlineOutlinedIcon color="primary" sx={iconSx} />,
      link: "/users",
    },
    {
      title: "Pending Transactions",
      // count: data?.totalUsers?.totalCount,
      count: transactions?.openedTransactions,
      icon: <Money color="primary" sx={iconSx} />,
      link: "/transactions/stats?status=PENDING",
    },
    {
      title: "Failed Transactions",
      // count: data?.totalUsers?.totalCount,
      count: transactions?.closedTransactions,
      icon: <Money color="primary" sx={iconSx} />,
      link: "/transactions/stats?status=FAILED",
    },
    {
      title: "Unknown Transactions",
      // count: data?.totalUsers?.totalCount,
      count: transactions?.unknownTransactions,
      icon: <Money color="primary" sx={iconSx} />,
      link: "/transactions/stats?status=UNKNOWN",
    },
  ];

  let filteredDetails: any[];
  if (userDetails?.role === "MARKETING") {
    // If user has specific routes defined, filter the Details
    filteredDetails = [
      {
        title: "Open Offers",
        count: data?.totalOffers?.openOffers,
        icon: <AdminPanelSettingsOutlined color="primary" sx={iconSx} />,
        link: "/offers/stats?status=OPEN",
      },
      {
        title: "Fulfilled Offers",
        count: data?.totalOffers?.fulfilledOffers,
        icon: <CheckCircleOutlineOutlinedIcon color="primary" sx={iconSx} />,
        link: "/offers/stats?status=SOLD",
      },
      {
        title: "Cancelled Offers",
        count: data?.totalOffers?.canceledOffers,
        icon: <CancelOutlinedIcon color="primary" sx={iconSx} />,
        link: "/offers/stats?status=CANCELLED",
      },
      {
        title: "Pending Orders",
        count: data?.totalOrders?.pendingOrders,
        icon: <PendingOutlinedIcon color="primary" sx={iconSx} />,
        link: "/orders/stats?status=PENDING",
      },
      {
        title: "Fulfilled Orders",
        count: data?.totalOrders?.fulfilledOrders,
        icon: <ShoppingCartOutlined color="primary" sx={iconSx} />,
        link: "/orders/stats?status=SUCCESSFUL",
      },
      {
        title: "Cancelled Orders",
        count: data?.totalOrders?.canceledOrders,
        icon: <CancelOutlinedIcon color="primary" sx={iconSx} />,
        link: "/orders/stats?status=CANCELLED",
      },
      {
        title: "Failed Orders",
        count: data?.totalOrders?.failedOrders,
        icon: <UnpublishedOutlinedIcon color="primary" sx={iconSx} />,
        link: "/orders/stats?status=FAILED",
      },
    ];
  } else if (userDetails?.role === "COMPLIANCE") {
    filteredDetails = [];
  } else {
    // Display all Details for other roles
    filteredDetails = details;
  }

  return (
    <Wrapper>
      {isLoading ? (
        <Loading />
      ) : error ? (
        <ErrorPage error={error} />
      ) : (
        <Box pb={4} component="section">
          <Grid container spacing={2}>
            {filteredDetails?.map((ordersDetail, index) => (
              <Grid key={index} item xs={12} sm={4} md={3}>
                <Card {...ordersDetail} />
              </Grid>
            ))}
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
              {data?.offerStats && (
                <BarChart offerStats={data?.graphOfferStats} />
              )}
              {data?.orderStats && (
                <RatigBarChart offerStats={data?.orderStats} />
              )}
            </Grid>
            <Grid item xs={12} md={4}>
              <DoughnutChart totalUsers={data?.totalUsers as TotalUsersInt} />
              <PieChart offerStats={data?.offerStats} />
            </Grid>
          </Grid>
        </Box>
      )}
    </Wrapper>
  );
};
export default DashboardPage;

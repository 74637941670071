import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/AddOutlined";

export default function FabButton({ onClick }: { onClick: Function }) {
  return (
    <Fab
      onClick={() => onClick()}
      color="primary"
      aria-label="add"
      sx={{ position: "fixed", right: 20, bottom: 20 }}
    >
      <AddIcon />
    </Fab>
  );
}

import Stack from "@mui/material/Stack";
import InputField from "Components/Shared/textField";
import { useForm } from "Utils/hooks";
import ActionButton from "Components/Shared/button";
import { request } from "Utils";
import { useState } from "react";
import Progress from "Components/Shared/circleLoading";
import DialogComponent from "Components/Shared/dailog";
import { useMutation } from "react-query";
import { UpdateCountryFunc } from "./type";
import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { getAllISOCodes } from "iso-country-currency";

const UpdateCountryInfo: UpdateCountryFunc = ({
  toggleToast,
  isOpen,
  closeDialog,
  refetch,
  countryInfo: country
}) => {
  const { getData, values, submit } = useForm(submitForm);
  const [isLoading, setIsloading] = useState(false);
  const countryInformation = getAllISOCodes()

  const mutation = useMutation(
    async () => {
      setIsloading(true);
      return await request.patch({
        url: `/school-config/${country.id}`,
        data: values,
      });
    },
    {
      onSuccess: () => {
        refetch();
        setIsloading(false);
        closeDialog();
      },
      onError: ({ message }) => {
        toggleToast(message);
        setIsloading(false);
        closeDialog();
      },
    },
  );
  function submitForm() {
    mutation.mutate();
  }

  return (
    <div>
      <DialogComponent
        open={isOpen}
        onClose={closeDialog}
        title={`Update ${country.country}  Information`}
      >
        <form onSubmit={submit}>
          <Stack spacing={4} py={4}>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
              }}
            >
              <FormControl sx={{ width: { xs: "100%", md: "100%" }, mb: 3 }}>
                <InputLabel>Country Name</InputLabel>
                <Select
                  name="country"
                  labelId="country"
                  label="Country Name"
                  onChange={(e: any) => getData(e)}
                  defaultValue={country.country.toUpperCase()}
                >
                  {countryInformation?.map((country, index) => (
                    <MenuItem key={`${index}-country`} value={country.countryName.toUpperCase()}>
                      {country.countryName.toUpperCase()}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={{ width: { xs: "100%", md: "100%" }, mb: 3 }}>
                <InputLabel>Iso 2</InputLabel>
                <Select
                  name="iso2"
                  labelId="iso2"
                  label="ISO 2"
                  onChange={(e: any) => getData(e)}
                  defaultValue={country.iso2}
                >
                  {countryInformation?.map((country, index) => (
                    <MenuItem key={`${index}-country`} value={country.iso}>
                      {country.iso}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={{ width: { xs: "100%", md: "100%" }, mb: 3 }}>
                <InputLabel>Currency</InputLabel>
                <Select
                  name="currency"
                  labelId="currency"
                  label="Currency"
                  onChange={(e: any) => getData(e)}
                  defaultValue={country.currency}
                >
                  {countryInformation
                    ?.filter((value, index, self) =>
                      self.findIndex((item) => item.currency === value.currency) === index // Filter out duplicates
                    )
                    .sort((a, b) => a.currency.localeCompare(b.currency)) // Sort by currency alphabetically
                    .map((country, index) => (
                      <MenuItem key={`${index}-country`} value={country.currency}>
                        {country.currency}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <InputField
                type="text"
                label="Limit"
                name="limit"
                defaultValue={country.limit}
                onChange={getData}
                sx={{ width: { xs: "100%", md: "100%" }, mb: 3 }}
              />
              <InputField
                type="text"
                label="Charges"
                name="charges"
                defaultValue={country.charges}
                onChange={getData}
                sx={{ width: { xs: "100%", md: "100%" }, mb: 3 }}
              />
            </Box>


            <ActionButton type="submit" variant="contained">
              Update Country Information{" "}
              {isLoading && (
                <Progress sx={{ color: "#fff", ml: 0.8 }} size={18} />
              )}
            </ActionButton>
          </Stack>
        </form>
      </DialogComponent>
    </div>
  );
};

export default UpdateCountryInfo;

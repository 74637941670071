import Box from "@mui/material/Box";

import ActionButton from "Components/Shared/button";
import { handleError, request } from "Utils";
import { useState } from "react";
import Progress from "Components/Shared/circleLoading";
import DialogComponent from "Components/Shared/dailog";
import { useMutation } from "react-query";
import { Typography } from "@mui/material";

export default function ConfirmDelete({
  toggleToast,
  isOpen,
  closeDialog,
  url,
  refetch,
}: {
  toggleToast: Function;
  isOpen: boolean;
  closeDialog: Function;
  url: string;
  refetch?: Function;
}) {
  const [isLoading, setIsloading] = useState(false);

  const mutation = useMutation(
    async () => {
      setIsloading(true);
      return await request.delete(url);
    },
    {
      onSuccess: ({ message }) => {
        toggleToast(message);
        refetch && refetch();
        setIsloading(false);
        closeDialog();
      },
      onError: (err) => {
        toggleToast(handleError(err).message);
        setIsloading(false);
      },
    },
  );

  return (
    <div>
      <DialogComponent
        open={isOpen}
        onClose={closeDialog}
        title="Confirm Delete"
        maxWidth="xs"
      >
        <Box py={2}>
          <Typography variant="body1" sx={{ fontSize: 16, lineHeight: 1.6 }}>
            Please confirm your intention to delete this item. Deleting it will
            result in its permanent removal from the system, rendering it
            unrecoverable.
          </Typography>
          <Box textAlign="right">
            <ActionButton
              onClick={() => mutation.mutate()}
              sx={{ color: "red", fontSize: 16 }}
            >
              Yes, Delete{" "}
              {isLoading && (
                <Progress sx={{ color: "#fff", ml: 0.8 }} size={18} />
              )}
            </ActionButton>
            <ActionButton
              onClick={() => closeDialog()}
              sx={{ color: "#616161", fontSize: 16 }}
            >
              No, Cancel
            </ActionButton>
          </Box>
        </Box>
      </DialogComponent>
    </div>
  );
}

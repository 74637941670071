import ErrorPage from "Components/Shared/error";
import Loading from "Components/Shared/loading";
import StickyHeadTable from "Components/Shared/table";
import Wrapper from "Components/Wrapper";
import { useState } from "react";
import { useQuery } from "react-query";
import { request } from "Utils";
import { UserInt } from "Utils/interface";
import NoContentFound from "Components/Shared/noContentFound";
import { SubscribersFunc } from "./type";

const getSubscribers = async ({ queryKey }: any) => {
  const [, pageId, limit] = queryKey;
  const { data } = await request.get({
    url: `/waitlist?pageId=${pageId}&limit=${limit}`,
  });
  return data;
};

const Subscribers: SubscribersFunc = () => {
  const [pageId, setPageId] = useState(1);
  const [limit, setLimit] = useState(10);
  const { data, error, isLoading, refetch } = useQuery({
    queryKey: ["Users", pageId, limit],
    queryFn: getSubscribers,
    retry: 2,
    // staleTime: 86400000,
  });

  const columns = [
    { minWidth: 20, name: "No", key: "index" },
    { minWidth: 50, name: "Name", key: "name" },
    { minWidth: 50, name: "Email", key: "email" },
    { minWidth: 50, name: "phoneNumber", key: "phoneNumber" },
    { minWidth: 50, name: "Status", key: "status" },
  ];
  const subscribers = data?.waitlists?.map((user: UserInt, index: number) => ({
    index: ++index + limit * (pageId - 1),

    ...user,
  }));
  if (isLoading) return <Loading />;
  if (error) return <ErrorPage error={error} />;
  return (
    <Wrapper>
      <div>
        {subscribers?.length ? (
          <StickyHeadTable
            refetch={refetch}
            data={subscribers}
            dataLength={data?.totalCount}
            columns={columns}
            setLimit={setLimit}
            limit={limit}
            setPageId={setPageId}
            pageId={pageId}
            pageCount={data.pageCount}
          />
        ) : (
          <NoContentFound />
        )}
      </div>
    </Wrapper>
  );
};

export default Subscribers;

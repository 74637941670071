import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import ErrorPage from "Components/Shared/error";
import Loading from "Components/Shared/loading";
import StickyHeadTable from "Components/Shared/table";
import Wrapper from "Components/Wrapper";
import { useState } from "react";
import { useQuery } from "react-query";
import { request } from "Utils";
import Usermenu from "./menu";
import NoContentFound from "Components/Shared/noContentFound";
import FabButton from "Components/Shared/fab";
import AddConfig from "./add";
import { useDailog, useToast } from "Utils/hooks";
import Toast from "Components/Shared/toast";
import { ConfigurationInt, ConfigurationTypeFunc } from "./type";

const getConfigTypes = async ({ queryKey }: any) => {
  const [, pageId, limit] = queryKey;
  const { data } = await request.get({
    url: `/config-types?pageId=${pageId + 1}&limit=${limit}`,
  });
  return data;
};

const ConfigurationType: ConfigurationTypeFunc = () => {
  const { isOpen, closeDialog, openDialog } = useDailog();
  const { toggleToast, toastMessage } = useToast();
  const [pageId, setPageId] = useState(1);
  const [limit, setLimit] = useState(10);
  const { data, error, isLoading, refetch } = useQuery({
    queryKey: ["Users", pageId, limit],
    queryFn: getConfigTypes,
    retry: 2,
    // staleTime: 86400000,
  });

  const columns = [
    { minWidth: 20, name: "No", key: "index" },
    { minWidth: 50, name: "Name", key: "name" },
    { minWidth: 50, name: "Action", key: "action" },
  ];
  const configurations =
    data &&
    data.length &&
    data?.map((config: ConfigurationInt, index: number) => ({
      index: ++index + limit * (pageId - 1),

      ...config,
      action: (
        <Usermenu config={config} refetch={refetch} toggleToast={toggleToast} />
      ),
    }));
  if (isLoading) return <Loading />;
  if (error) return <ErrorPage error={error} />;
  return (
    <Wrapper>
      <Container maxWidth="md">
        {data?.length ? (
          <Box
            pb={5}
            sx={{ height: configurations?.length <= 7 ? "77vh" : "" }}
          >
            <StickyHeadTable
              refetch={refetch}
              data={configurations}
              dataLength={data?.totalCount}
              columns={columns}
              setLimit={setLimit}
              limit={limit}
              setPageId={setPageId}
              pageId={pageId}
              pageCount={data.pageCount}
            />
          </Box>
        ) : (
          <NoContentFound />
        )}
        <AddConfig
          refetch={refetch}
          isOpen={isOpen}
          closeDialog={closeDialog}
          toggleToast={toggleToast}
        />

        <FabButton onClick={openDialog} />
        {Boolean(toastMessage) && (
          <Toast
            open={Boolean(toastMessage)}
            message={toastMessage}
            onClose={() => toggleToast("")}
          />
        )}
      </Container>
    </Wrapper>
  );
};
export default ConfigurationType;

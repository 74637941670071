import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Wrapper from "Components/Wrapper";
import { useQuery } from "react-query";
import { request } from "Utils";
import Loading from "Components/Shared/loading";
import ErrorPage from "Components/Shared/error";
import { DashboardFunc } from "./types";
import BalanceCard from "./card";

const getConfigurations = async () => {
  const { data } = await request.get({ url: "/wallet/balance-summary" });
  return data;
};

const Balances: DashboardFunc = () => {
  const { data, error, isLoading } = useQuery({
    queryKey: ["dashboard"],
    queryFn: getConfigurations,
    refetchInterval: 60000,
    retry: 2,
  });

  return (
    <Wrapper>
      {isLoading ? (
        <Loading />
      ) : error ? (
        <ErrorPage error={error} />
      ) : (
        <Box pb={4} component="section">
          <Grid container spacing={2}>
            {Array.isArray(data) &&
              data?.map((balanceDetails: any, index: number) => (
                <Grid key={index} item xs={12} sm={4} md={3}>
                  <BalanceCard {...balanceDetails} />
                </Grid>
              ))}
          </Grid>
        </Box>
      )}
    </Wrapper>
  );
};
export default Balances;

import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";

const MenuComponent = ({
  name,
  icon,
  onclick,
  deleteComponent,
}: {
  name: string;
  icon: JSX.Element;
  onclick: Function;
  deleteComponent: boolean;
}): JSX.Element => {
  return (
    <MenuItem
      disableRipple
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 5,
        border: "solid 1px #dbdbdb",
        py: 2.5,
        px: 0.5,
        maxWidth: 110,
      }}
      onClick={() => onclick()}
    >
      {icon}
      <Typography
        variant="body2"
        sx={{ mt: 1.5, color: deleteComponent ? "red" : "" }}
      >
        {name}
      </Typography>
    </MenuItem>
  );
};

export default MenuComponent;

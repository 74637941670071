import Stack from "@mui/material/Stack";
import { useDailog, useForm } from "Utils/hooks";
import ActionButton from "Components/Shared/button";
import InputField from "Components/Shared/textField";
import DialogComponent from "Components/Shared/dailog";
import { useState } from "react";
import Progress from "Components/Shared/circleLoading";
import Box from "@mui/material/Box";
import { request } from "Utils";
import { useMutation } from "react-query";
import { Button } from "@mui/material";

const Menu = ({ toggleToast, refetch, apiEndpoint }: any) => {
  const { isOpen, closeDialog, openDialog } = useDailog();
  const { values, getData, submit } = useForm(submitForm);
  const [isLoading, setIsloading] = useState(false);

  const sx = {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    alignItems: "center",
    width: "100%"
  };

  // Moved useMutation inside the component
  const mutation = useMutation(
    async () => {
      return await request.post({
        url: apiEndpoint, // Use the passed `apiEndpoint`
        data: values
      });
    },
    {
      onSuccess: ({ message }) => {
        toggleToast(message); // Display the success message in a toast notification
        setIsloading(false);
        closeDialog();
        refetch();
      },
      onError: ({ message }) => {
        toggleToast(message);
        setIsloading(false);
        closeDialog();
      }
    }
  );

  function submitForm(event: React.FormEvent) {
    event.preventDefault();
    setIsloading(true);
    
    mutation.mutateAsync().then(() => {
        closeDialog(); // Close the dialog after successful submission
    }).catch(() => {
        // Handle error, dialog will still close
        closeDialog();
    }).finally(() => {
        setIsloading(false); // Reset loading state
    });
}


  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        sx={{ marginBottom: 2 }}
        onClick={openDialog}
      >
        Update Bdc payment
      </Button>

      <DialogComponent
        open={isOpen}
        onClose={closeDialog}
        title="Update Bdc payment"
        maxWidth="sm"
      >
        <Stack spacing={3} py={2}>
          <form onSubmit={submitForm}>
            <Stack spacing={3} py={3}>
              <Box sx={sx}>
                <InputField
                  type="text"
                  label="Currency"
                  name="currency"
                  onChange={getData}
                  sx={{ width: { xs: "100%", md: "48%" } }}
                  required
                  inputProps={{
                    pattern: "[A-Za-z]*",
                    title: "Only letters are allowed"
                  }}
                />
                <InputField
                  type="number"
                  label="Amount"
                  name="amount"
                  onChange={getData}
                  sx={{ width: { xs: "100%", md: "48%" } }}
                  required
                  inputProps={{
                    step: "0.0001" // Allows up to two decimal places
                  }}
                />
              </Box>

              <Box sx={sx}>
                <InputField
                  type="text"
                  label="Bdc Id"
                  name="bdcId"
                  onChange={getData}
                  sx={{ width: { xs: "100%", md: "48%" } }}
                  required
                />
                <InputField
                  type="text"
                  label="Country"
                  name="iso2"
                  onChange={getData}
                  sx={{ width: { xs: "100%", md: "48%" } }}
                  required
                />
              </Box>

              <ActionButton type="submit" variant="contained">
                Submit
                {isLoading && (
                  <Progress
                    sx={{
                      width: { xs: "100%", md: "40%" },
                      mb: 2,
                      // color: "#fff", ml: 0.8,
                      backgroundColor: "#606676",
                      "&:hover": {
                        backgroundColor: "#606676"
                      }
                    }}
                    size={18}
                  />
                )}
              </ActionButton>
            </Stack>
          </form>
        </Stack>
      </DialogComponent>
    </div>
  );
};

export default Menu;

import MenuItem from "@mui/material/MenuItem";

import Grid from "@mui/material/Grid";
import { useDailog } from "Utils/hooks";
import DialogComponent from "Components/Shared/dailog";
import { Typography } from "@mui/material";
import { BankDetailsFunc } from "./type";

const BankDetails: BankDetailsFunc = ({ bankDetails }) => {
  const { isOpen, closeDialog, openDialog } = useDailog();

  return (
    <div>
      <MenuItem disableRipple onClick={() => openDialog()}>
        Bank Details
      </MenuItem>
      <DialogComponent
        open={isOpen}
        onClose={closeDialog}
        title="Bank details"
        maxWidth="sm"
      >
        <Grid container spacing={1.5} py={3}>
          <Grid item xs={6}>
            <Typography>Account name:</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>{bankDetails?.accountName}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>Account number:</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>{bankDetails?.accountNumber}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>Bank code:</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>{bankDetails?.bankCode}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>Bank name:</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>{bankDetails?.bankName}</Typography>
          </Grid>
        </Grid>
      </DialogComponent>
    </div>
  );
};
export default BankDetails;

import Stack from "@mui/material/Stack";
import InputField from "Components/Shared/textField";

import { useForm } from "Utils/hooks";
import ActionButton from "Components/Shared/button";
import { request } from "Utils";
import { useState } from "react";
import Progress from "Components/Shared/circleLoading";
import DialogComponent from "Components/Shared/dailog";
import { useMutation } from "react-query";
import { AddRoleFunc } from "./type";

const AddRole: AddRoleFunc = ({
  toggleToast,
  isOpen,
  closeDialog,
  refetch,
}) => {
  const { getData, values, submit, reset } = useForm(submitForm);
  const [isLoading, setIsloading] = useState(false);

  const mutation = useMutation(
    async () => {
      setIsloading(true);
      return await request.post({
        url: "/role",
        data: values,
      });
    },
    {
      onSuccess: ({ message }) => {
        reset();
        toggleToast(message);
        refetch();
        setIsloading(false);
        closeDialog();
      },
      onError: ({ message }) => {
        toggleToast(message);
        setIsloading(false);
      },
    },
  );
  function submitForm() {
    mutation.mutate();
  }
  return (
    <div>
      <DialogComponent
        open={isOpen}
        onClose={closeDialog}
        title="Create new role"
        maxWidth="xs"
      >
        <form onSubmit={submit}>
          <Stack spacing={4} py={5}>
            <InputField
              type="text"
              label="Name"
              name="name"
              onChange={getData}
              required
            />

            <ActionButton type="submit" variant="contained">
              Create role{" "}
              {isLoading && (
                <Progress sx={{ color: "#fff", ml: 0.8 }} size={18} />
              )}
            </ActionButton>
          </Stack>
        </form>
      </DialogComponent>
    </div>
  );
};
export default AddRole;

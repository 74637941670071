import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";

import InputField from "Components/Shared/textField";
import { useForm } from "Utils/hooks";
import ActionButton from "Components/Shared/button";
import { request } from "Utils";
import { useState } from "react";
import Progress from "Components/Shared/circleLoading";
import DialogComponent from "Components/Shared/dailog";
import { useMutation } from "react-query";
import {
  AddConfigConfigConfigInt,
  ConfigConfigurationProps,
  ConfigurationInt,
} from "./type";
import NoContentFound from "Components/Shared/noContentFound";

const AddConfigConfig: AddConfigConfigConfigInt = ({
  toggleToast,
  refetch,
  configurations: configuration,
  id,
  dialogModal,
  addConfigTypes,
}) => {
  const { submit } = useForm(submitForm);
  const [isLoading, setIsloading] = useState(false);
  const [configurations, setConfigurations] = useState<
    Array<ConfigConfigurationProps>
  >([]);
  const types = ["PERCENTAGE", "FIXED", "TEXT", "BOOLEAN"];
  const keys = configuration.map((config) => config.configTypeId);
  const mutation = useMutation(
    async () => {
      setIsloading(true);
      return await request.patch({
        url: `/configuration/${id}/add`,
        data: { configurations },
      });
    },
    {
      onSuccess: ({ message }) => {
        toggleToast(message);
        refetch();
        setIsloading(false);
        dialogModal.closeDialog();
      },
      onError: ({ message }) => {
        toggleToast(message);
        setIsloading(false);
      },
    },
  );
  function submitForm() {
    mutation.mutate();
  }
  function idIncluded(id: string) {
    const value = configurations.find((element) => element.configTypeId === id);
    if (value)
      return configurations.findIndex(
        (element) => element.configTypeId === value.configTypeId,
      );
    else return null;
  }

  function setConfigurationsFunc(id: string, value: string) {
    const index = idIncluded(id);
    if (index || index === 0) configurations[index].type = value;
    else
      configurations.push({
        configTypeId: id,
        type: value,
        value: 0,
      });

    setConfigurations([...configurations]);
  }

  function setConfigurationsValue(id: string, value: number) {
    const index = idIncluded(id);
    if (index || index === 0) configurations[index].value = value;
    else
      configurations.push({
        configTypeId: id,
        type: "",
        value,
      });
    setConfigurations([...configurations]);
  }

  return (
    <div>
      <DialogComponent
        open={dialogModal.isOpen}
        onClose={dialogModal.closeDialog}
        title="Update Configuration"
        maxWidth="sm"
      >
        {addConfigTypes?.length ? (
          <form onSubmit={submit}>
            <Stack spacing={3} py={3}>
              <Stack spacing={2} pr={2}>
                {addConfigTypes?.map(
                  (config: ConfigurationInt, index: number) =>
                    !keys.includes(config.id) && (
                      <Grid
                        container
                        spacing={2}
                        key={`configuration ${index}`}
                      >
                        <Grid item md={4}>
                          <Typography>{config.name}</Typography>
                        </Grid>
                        <Grid item md={4}>
                          <FormControl fullWidth>
                            <InputLabel>Select Type </InputLabel>
                            <Select
                              labelId="type"
                              label="Select Type"
                              onChange={(e: any) =>
                                setConfigurationsFunc(config.id, e.target.value)
                              }
                            >
                              {types?.map((type, index) => (
                                <MenuItem key={`${index}-type`} value={type}>
                                  {type}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item md={4}>
                          <InputField
                            fullWidth
                            type="text"
                            label="Value"
                            name="value"
                            onChange={(e) =>
                              setConfigurationsValue(
                                config.id,
                                Number(e.target.value),
                              )
                            }
                          />
                        </Grid>
                      </Grid>
                    ),
                )}
              </Stack>

              <ActionButton type="submit" variant="contained">
                Update Configuration
                {isLoading && (
                  <Progress sx={{ color: "#fff", ml: 0.8 }} size={18} />
                )}
              </ActionButton>
            </Stack>
          </form>
        ) : (
          <NoContentFound message="You have added all the configuration available" />
        )}
      </DialogComponent>
    </div>
  );
};

export default AddConfigConfig;

import React, { useEffect, useState } from "react";
import { ChipInputFunc, ChipInputInt } from "./type";
import { Chip, TextField } from "@mui/material";
import { Cancel } from "@mui/icons-material";

const ChipInput: ChipInputFunc = (props: ChipInputInt) => {
  const [inputValue, setInputValue] = useState<string>("");
  const [chips, setChips] = useState<string[]>([]);
  const [renderedChips, setRenderedChips] = useState<string[]>([]);

  useEffect(() => {
    let mails = props.users_list?.map((user) => user.email);
    setChips(mails ?? []);
  }, [props.users_list]);

  useEffect(() => {
    setRenderedChips(chips.slice(0, 10));
  }, [chips]);

  const handleInputChange = (e: any) => {
    setInputValue(e.target.value);
  };

  const handleChipDelete = (chipToDelete: string) => {
    setChips((chips) => chips.filter((chip) => chip !== chipToDelete));
  };

  const handleInputKeyDown = (e: any) => {
    if (e.key === "Enter" && inputValue.trim()) {
      setChips([...chips, inputValue.trim()]);
      setInputValue("");
    }
  };
  const remainingCount = chips.length - renderedChips.length;
  return (
    <TextField
      sx={{
        width: "100%",
        marginBottom: "10px",
        display: "flex",
        flexWrap: "wrap",
      }}
      label={props.label}
      value={inputValue}
      onChange={handleInputChange}
      onKeyDown={handleInputKeyDown}
      disabled
      InputProps={{
        startAdornment: (
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              gap: "4px",
              maxWidth: "100%",
              boxSizing: "border-box",
              padding: "5px",
            }}
          >
            {renderedChips.map((chip: any, index) => (
              <Chip
                key={index}
                label={chip}
                onDelete={() => handleChipDelete(chip)}
                deleteIcon={<Cancel />}
              />
            ))}
            {remainingCount > 0 && (
              <Chip
                onClick={() => {
                  setRenderedChips(chips);
                }}
                label={`+${remainingCount}`}
              />
            )}
          </div>
        ),
      }}
    />
  );
};

export default ChipInput;

import React, { useState, useEffect } from 'react';
import { TransactionFunc, TransactionInt } from './type';
import { useToast } from 'Utils/hooks';
import { useQuery } from 'react-query';
import Loading from 'Components/Shared/loading';
import ErrorPage from 'Components/Shared/error';
import { Box, Container, Typography } from '@mui/material';
import Wrapper from 'Components/Wrapper';
import NoContentFound from 'Components/Shared/noContentFound';
import StickyHeadTable from 'Components/Shared/table';
import Toast from 'Components/Shared/toast';
import { request } from 'Utils';
import PaymentMenu from './menu';
import PaymentFilter from './filter';
import { useLocation } from 'react-router-dom';

const getTransactions = async ({ queryKey }: any) => {
    const [, filter] = queryKey;
    const { data } = await request.get({
        url: `/school-fee-payment${filter}`,
    });
    return data;
};

const TuitionTransactions: TransactionFunc = () => {
    const { toggleToast, toastMessage } = useToast();
    const [pageId, setPageId] = useState(1);
    const [limit, setLimit] = useState(10);
    const [paginatedData, setPaginatedData] = useState<TransactionInt[]>([]);
    const query = useLocation();
    const { data, error, isLoading, refetch } = useQuery({
        queryKey: ["Users", query.search],
        queryFn: getTransactions,
        retry: 2,
    });

    useEffect(() => {
        if (data) {
            const startIndex = (pageId - 1) * limit;
            const endIndex = startIndex + limit;
            setPaginatedData(data.slice(startIndex, endIndex));
        }
    }, [data, pageId, limit]);

    const columns = [
        { minWidth: 20, name: "No", key: "index" },
        { minWidth: 80, name: "Student Name", key: "studentName" },
        { minWidth: 50, name: "Reference Number", key: "studentReferenceNo" },
        { minWidth: 50, name: "School Fee", key: "schoolFee" },
        { minWidth: 50, name: "Actual Amount", key: "actualAmount" },
        { minWidth: 50, name: "Charges Collected", key: "chargesCollected" },
        { minWidth: 50, name: "Amount Deducted", key: "amountDeducted" },
        { minWidth: 50, name: "Rate", key: "rate" },
        { minWidth: 50, name: "Charges", key: "charges" },
        { minWidth: 50, name: "From Currency", key: "fromCurrency" },
        { minWidth: 50, name: "To Currency", key: "toCurrency" },
        { minWidth: 50, name: "Status", key: "status" },
        { minWidth: 50, name: "Action", key: "action" },
    ];

    const transactions = paginatedData.map((transaction: TransactionInt, index: number) => ({
        index: ++index + limit * (pageId - 1),
        ...transaction,
        status: (
            <>
                {transaction.status === 'PENDING' ? <Typography sx={{ color: 'red' }}>{transaction.status}</Typography> : <Typography sx={{ color: 'darkgreen' }}>{transaction.status}</Typography>}
            </>
        ),
        action: (
            <PaymentMenu refetch={refetch} toggleToast={toggleToast} schoolId={transaction.schoolId} countryId={transaction.countryId} transaction={transaction} />
        )
    }));

    if (isLoading) return <Loading />;
    if (error) return <ErrorPage error={error} />;

    return (
        <Wrapper>
            <Container maxWidth="xl">
                {transactions?.length ? (
                    <Box
                        pb={5}
                        sx={{ height: transactions?.length <= 7 ? "77vh" : "" }}
                    >
                        <Box textAlign="right">
                            <PaymentFilter />
                        </Box>
                        <StickyHeadTable
                            refetch={refetch}
                            data={transactions}
                            dataLength={data.length}
                            columns={columns}
                            setLimit={setLimit}
                            limit={limit}
                            setPageId={setPageId}
                            pageId={pageId}
                        />
                    </Box>
                ) : (
                    <NoContentFound />
                )}

                {Boolean(toastMessage) && (
                    <Toast
                        open={Boolean(toastMessage)}
                        message={toastMessage}
                        onClose={() => toggleToast("")}
                    />
                )}
            </Container>
        </Wrapper>
    );
};

export default TuitionTransactions;

import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Rolemenu from "Components/Configuration/menu";
import { useMenu } from "Utils/hooks";
import { CountryCardFunc } from "./type";

const CountryCard: CountryCardFunc = ({ refetch, toggleToast, config }) => {
  const { country, countryFlag } = config;

  const { anchorEl, menuIsOpen, closeMenu, openMenu } = useMenu();
  return (
    <>
      <Card
        onClick={openMenu}
        sx={{ boxShadow: "0px 1px 70px 1px #f1f1f1", cursor: "pointer" }}
      >
        <CardContent>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center"
            }}
            mt={2}
          >
            <Avatar src={countryFlag} alt={country} />
            <Typography variant="h5" style={{ color: "#333333" }} mt={2}>
              {country}
            </Typography>
          </Box>
        </CardContent>
      </Card>
      <Rolemenu
        refetch={refetch}
        toggleToast={toggleToast}
        {...config}
        anchorEl={anchorEl}
        menuIsOpen={menuIsOpen}
        closeMenu={closeMenu}
      />
    </>
  );
};
export default CountryCard;

import React, { useEffect, useState } from 'react'
import { SchoolInt, ViewSchoolsFunc } from './type';
import { useDailog, useToast } from 'Utils/hooks';
import { useQuery } from 'react-query';
import Loading from 'Components/Shared/loading';
import ErrorPage from 'Components/Shared/error';
import { Box, Container } from '@mui/material';
import Wrapper from 'Components/Wrapper';
import NoContentFound from 'Components/Shared/noContentFound';
import StickyHeadTable from 'Components/Shared/table';
import AddSchools from './addSchools';
import FabButton from 'Components/Shared/fab';
import Toast from 'Components/Shared/toast';
import { request } from 'Utils';
import { useParams } from 'react-router-dom';
import SchoolMenu from './schoolMenu';

const getSchools = async ({ queryKey }: any) => {
    const [, iso2] = queryKey;
    const { data } = await request.get({
        url: `/school-config/${iso2}`,
    });
    return data;
};


const ViewSchools: ViewSchoolsFunc = () => {
    const { iso2 } = useParams();
    const { isOpen, openDialog, closeDialog } = useDailog()
    const { toggleToast, toastMessage } = useToast();
    const [paginatedData, setPaginatedData] = useState<SchoolInt[]>([]);
    const [pageId, setPageId] = useState(1);
    const [limit, setLimit] = useState(10);
    const { data, error, isLoading, refetch } = useQuery({
        queryKey: ["iso2", iso2],
        queryFn: getSchools,
        retry: 2,
        // staleTime: 86400000,
    });

    useEffect(() => {
        if (data) {
            const startIndex = (pageId - 1) * limit;
            const endIndex = startIndex + limit;
            setPaginatedData(data.schools.slice(startIndex, endIndex));
        }
    }, [data, pageId, limit]);

    const columns = [
        { minWidth: 20, name: "No", key: "index" },
        { minWidth: 100, name: "School Name", key: "name" },
        { minWidth: 100, name: "Bank Account", key: "accountNo" },
        { minWidth: 50, name: "Bank Name", key: "bankName" },
        { minWidth: 50, name: "Bank Address", key: "bankAddress" },
        { minWidth: 50, name: "Account Name", key: "accountName" },
        { minWidth: 50, name: "Iban", key: "iban" },
        { minWidth: 50, name: "Swift Code", key: "swiftCode" },
        { minWidth: 50, name: "Action", key: "action" },
    ];

    const schools = paginatedData.map((school: SchoolInt, index: number) => ({
        index: ++index + limit * (pageId - 1),
        ...school,
        action: (
            <SchoolMenu school={school} refetch={refetch} toggleToast={toggleToast} countryId={data._id} />
        ),
    }))
    if (isLoading) return <Loading />;
    if (error) return <ErrorPage error={error} />;

    return (
        <Wrapper>
            <Container maxWidth="xl">
                {schools?.length ? (
                    <Box
                        pb={5}
                        sx={{ height: schools?.length <= 7 ? "77vh" : "" }}
                    >
                        <StickyHeadTable
                            refetch={refetch}
                            data={schools}
                            dataLength={data.schools.length}
                            columns={columns}
                            setLimit={setLimit}
                            limit={limit}
                            setPageId={setPageId}
                            pageId={pageId}
                            pageCount={data.pageCount}
                        />
                    </Box>
                ) : (
                    <NoContentFound />
                )}

                <AddSchools
                    refetch={refetch}
                    isOpen={isOpen}
                    closeDialog={closeDialog}
                    toggleToast={toggleToast}
                    countryId={data._id}
                />
                <FabButton onClick={openDialog} />
                {Boolean(toastMessage) && (
                    <Toast
                        open={Boolean(toastMessage)}
                        message={toastMessage}
                        onClose={() => toggleToast("")}
                    />
                )}
            </Container>
        </Wrapper>
    )
}

export default ViewSchools

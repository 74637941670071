import Stack from "@mui/material/Stack";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";

import { useForm } from "Utils/hooks";
import ActionButton from "Components/Shared/button";
import { request } from "Utils";
import { useState } from "react";
import Progress from "Components/Shared/circleLoading";
import DialogComponent from "Components/Shared/dailog";
import { useMutation, useQuery } from "react-query";
import { RestRoleFunc } from "./type";

const getRoles = async () => {
  const { data } = await request.get({ url: "/roles" });
  return data;
};

const RestRole: RestRoleFunc = ({
  toggleToast,
  isOpen,
  closeDialog,
  roleId,
  refetch,
  url,
}) => {
  const { getData, values, submit } = useForm(submitForm);
  const { data } = useQuery({ queryKey: ["roles"], queryFn: getRoles });
  const [isLoading, setIsloading] = useState(false);

  const mutation = useMutation(
    async () => {
      setIsloading(true);
      return await request.patch({
        url,
        data: values,
      });
    },
    {
      onSuccess: ({ message }) => {
        toggleToast(message);
        refetch();
        setIsloading(false);
        closeDialog();
      },
      onError: ({ message }) => {
        toggleToast(message);
        setIsloading(false);
      },
    },
  );
  function submitForm() {
    mutation.mutate();
  }
  return (
    <div>
      <DialogComponent
        open={isOpen}
        onClose={closeDialog}
        title="Change admin user role"
        maxWidth="xs"
      >
        <form onSubmit={submit}>
          <Stack spacing={4} py={5}>
            <FormControl fullWidth>
              <InputLabel>Assign Role *</InputLabel>
              <Select
                name="roleId"
                labelId="roleId"
                label="Select Role"
                value={values.roleId || roleId}
                onChange={(e: any) => getData(e)}
              >
                {data?.map(
                  ({ name, id }: { name: string; id: any }, index: number) => (
                    <MenuItem key={`${index}-type`} value={id}>
                      {name}
                    </MenuItem>
                  ),
                )}
              </Select>
            </FormControl>

            <ActionButton type="submit" variant="contained">
              Update Admin{" "}
              {isLoading && (
                <Progress sx={{ color: "#fff", ml: 0.8 }} size={18} />
              )}
            </ActionButton>
          </Stack>
        </form>
      </DialogComponent>
    </div>
  );
};

export default RestRole;

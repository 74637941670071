import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import ErrorPage from "Components/Shared/error";
import Loading from "Components/Shared/loading";
import NoContentFound from "Components/Shared/noContentFound";
import Toast from "Components/Shared/toast";
import Wrapper from "Components/Wrapper";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import { request } from "Utils";
import { useToast } from "Utils/hooks";
import { UserDataItem } from "./type";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";

const getUserData = async ({ queryKey }: any) => {
  const [id] = queryKey;
  const { data } = await request.get({ url: `/user/${id}` });
  return data;
};

const UserData: UserDataItem = () => {
  const { toggleToast, toastMessage } = useToast();
  const [getQuery] = useSearchParams();
  const id = getQuery.get("userId");

  const { data, error, isLoading } = useQuery({
    queryKey: [id],
    queryFn: getUserData,
    retry: 2
    // staleTime: 86400000,
  });
  const entries: [string, string][] = data ? Object.entries(data) : [];

  if (isLoading) return <Loading />;
  if (error) return <ErrorPage error={error} />;

  const renderNestedObject = (obj: Record<string, any>) => {
    return (
      <TableContainer
        component={Paper}
        sx={{ marginLeft: "20px", marginTop: "10px" }}
      >
        <Table size="small">
          <TableBody>
            {Object.entries(obj).map(([nestedKey, nestedValue]) => (
              <TableRow key={nestedKey}>
                <TableCell sx={{ fontWeight: "600", fontSize: "20px" }}>
                  {nestedKey}
                </TableCell>
                <TableCell sx={{ fontWeight: "400", fontSize: "14px" }}>
                  {nestedValue}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <Wrapper>
      {!isLoading ? (
        <Box sx={{ height: "85.5vh", position: "relative" }}>
          <Card sx={{ mt: 1.0, boxShadow: "0px 1px 70px 1px #f1f1f1" }}>
            <CardContent sx={{ textAlign: "start" }}>
              <TableContainer
                component={Paper}
                sx={{ minWidth: "600px", margin: "auto", marginTop: "20px" }}
              >
                <Table size="medium">
                  <TableBody>
                    {entries.map(([key, value]) => (
                      <TableRow key={key}>
                        <TableCell sx={{ fontWeight: "600", fontSize: "20px" }}>
                          {key}
                        </TableCell>
                        <TableCell sx={{ fontWeight: "400", fontSize: "18px" }}>
                          {typeof value === "object"
                            ? renderNestedObject(value)
                            : value}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
          {Boolean(toastMessage) && (
            <Toast
              open={Boolean(toastMessage)}
              message={toastMessage}
              onClose={() => toggleToast("")}
            />
          )}
        </Box>
      ) : (
        <NoContentFound />
      )}
    </Wrapper>
  );
};

export default UserData;

import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import FormGroup from "@mui/material/FormGroup";

import { useForm } from "Utils/hooks";
import ActionButton from "Components/Shared/button";
import { handleError, request } from "Utils";
import { useState } from "react";
import Progress from "Components/Shared/circleLoading";
import DialogComponent from "Components/Shared/dailog";
import { useMutation } from "react-query";
import CheckboxComponents from "Components/Shared/checkbox";
import { Typography } from "@mui/material";
import { AssignKYCFunc } from "./type";

const AssignKYC: AssignKYCFunc = ({
  toggleToast,
  currency,
  data,
  dialogModal
}) => {
  const { submit, reset } = useForm(submitForm);
  const [kycTypeIds, setkycTypeIds] = useState<Array<string>>([]);
  const [loading, setIsloading] = useState(false);

  const mutation = useMutation(
    async () => {
      setIsloading(true);
      return await request.post({
        url: "/wallet-kyc",
        data: { kycTypeIds, currency }
      });
    },
    {
      onSuccess: ({ message }) => {
        reset();
        toggleToast(message);
        setIsloading(false);
        dialogModal.closeDialog();
      },
      onError: (err) => {
        toggleToast(handleError(err).message);
        setIsloading(false);
      }
    }
  );

  function submitForm() {
    mutation.mutate();
  }

  function setkycTypeIdsFunc(id: string) {
    if (kycTypeIds.includes(id)) {
      const index = kycTypeIds.indexOf(id);
      kycTypeIds.splice(index, 1);
      setkycTypeIds([...kycTypeIds]);
    } else {
      setkycTypeIds([...kycTypeIds, id]);
    }
  }

  return (
    <div>
      <DialogComponent
        open={dialogModal.isOpen}
        onClose={dialogModal.closeDialog}
        title="Assign KYC to Currency"
      >
        <form onSubmit={submit}>
          <Box mt={4} mb={6}>
            <Stack spacing={5}>
              <Stack spacing={1}>
                <Typography variant="h6" fontWeight={700}>
                  Select KYC to be assigned
                </Typography>
                <FormGroup>
                  <Grid container sx={{ display: "flex", flexWrap: "wrap" }}>
                    {data?.map((kyc: any, index: number) => (
                      <Grid item xs={12} md={4} key={`kyc${index}`}>
                        <CheckboxComponents
                          key={index}
                          sx={{ mr: 4 }}
                          label={kyc.name}
                          onClick={() => setkycTypeIdsFunc(kyc.id)}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </FormGroup>
              </Stack>

              <ActionButton type="submit" variant="contained">
                Assign KYC{" "}
                {loading && (
                  <Progress sx={{ color: "#fff", ml: 0.8 }} size={18} />
                )}
              </ActionButton>
            </Stack>
          </Box>
        </form>
      </DialogComponent>
    </div>
  );
};
export default AssignKYC;
